import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { PageHeader, Pagination } from '../../components';
import CheckableDataTable from '../../components/CheckableDataTable/CheckableDataTable';
import useComponentState from '../../utils/useComponentState';
import { getAllOTPLogs, deleteOTPLogs, searchOTPLogs } from '../../api/OTPLogs';
import Search from '../../components/Search/Search.component';
import RowPaginator from '../../components/RowPaginator/RowPaginator';
import styles from './OTPLogs.module.scss';

const OTPLogs = (props) => {
  const [state, setState] = useComponentState({
    tableHeading: {
      column: [
        'Mobile Number',
        'Blocked Time',
        'Invalid OTP Count',
        'Sent OTP Count',
        'Created Time',
      ],
    },
    OTPData: [],
    OTPPages: 0,
    curPage: 0,
    OTPBatchPages: 0,
    OTPSearchPages: 0,
    curBatchPage: 0,
    searchPage: 1,
    pageSize: 500,
    rowSize: 10,
    searchInput: '',
    isSelected: false,
    isApiCalled: false,
    receiverName: '',
    searchId: '',
    slicedOTPData: [],
    tableData: [],
    OTPLoadingError: '',
    loading: false,
    searchedMerchantMessage: '',
    selectedOTP: null,
    isClearable: false,
    checkedRows: [],
    selectAll: false,
    filteredData: [],
  });
  const {
    tableHeading,
    tableData,
    OTPData,
    curPage,
    curBatchPage,
    OTPBatchPages,
    searchInput,
    filteredData,
    slicedOTPData,
    OTPLoadingError,
    loading,
    OTPPages,
    rowSize,
    searchPage,
    searchedMerchantMessage,
    isClearable,
    pageSize,
    isSelected,
    isApiCalled,
    checkedRows,
    selectAll,
  } = state;

  useEffect(() => {
    getOTPDetails(0);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filteredData) {
      setState({
        OTPPages: getOTPPages(filteredData),
        slicedOTPData: filteredData.slice(0, rowSize),
      });
    }
    //eslint-disable-next-line
  }, [filteredData]);

  useEffect(() => {
    if (OTPData) {
      const tableData = OTPData.map(
        ({ recipient, invalid, time, sent, createdAt }) => ({
          recipient,
          invalid,
          time,
          sent,
          createdAt,
        })
      );
      setState({
        OTPPages: getOTPPages(OTPData),
        tableData: tableData,
        slicedOTPData: tableData.slice(0, rowSize),
        /* OTPBatchPages: getOTPBatchPages(OTPData), */
      });
    }
    //eslint-disable-next-line
  }, [OTPData]);

  useEffect(() => {
    setState({
      OTPPages: getOTPPages(OTPData),
      slicedOTPData: tableData.slice(0, rowSize),
    });

    //eslint-disable-next-line
  }, [rowSize]);

  const getOTPDetails = async (page) => {
    setState({
      loading: true,
      OTPLoadingError: '',
      checkedRows: [],
    });
    try {
      const response = await getAllOTPLogs(
        props.auth.auth.token,
        page,
        pageSize
      );
      setState({
        OTPData: response.otpLogs,
        OTPBatchPages: response.totalPage,
        curBatchPage: response.pageNo /* OTPBatchPages: response */,
        isApiCalled: false,
      });
    } catch (error) {
      setState({
        OTPLoadingError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ loading: false });
  };

  const searchOTPDetails = async (value, page) => {
    setState({
      loading: true,
      OTPLoadingError: '',
      checkedRows: [],
    });
    try {
      const response = await searchOTPLogs(
        props.auth.auth.token,
        value,
        page ? page + 1 : (page = 1),
        pageSize
      );
      setState({
        OTPData: response.otpLogs,
        OTPBatchPages: response.totalPage,
        isApiCalled: true,
      });
    } catch (error) {
      setState({
        OTPLoadingError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ loading: false });
  };

  const handleDeleteOTPLogs = async () => {
    setState({
      loading: true,
    });
    try {
      const token = props.auth.auth.token;
      const response = await deleteOTPLogs(checkedRows, token);
      getOTPDetails(curBatchPage);
    } catch (error) {
      setState({
        fetchError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ loading: false });
  };

  /* const handleOTPNameSearch = (name) => {
    if (name) {
      const filtered = OTPData.filter((data) => {
        if (data.recipient === null) {
          return false;
        }
        return data.recipient.toLowerCase().includes(name.toLowerCase());
      });
      setState({
        filteredData: filtered,
      });
    }
  }; */

  const searchOnChange = (value) => {
    if (/^\+?[0-9]*$/.test(value)) {
      setState({
        searchInput: value,
        isClearable: false,
        isApiCalled: false,
      });
    }
  };

  const handleOTPClear = () => {
    getOTPDetails(curBatchPage);
  };

  const handleRowPaginationChange = (event) => {
    setState({
      rowSize: event.target.value,
    });
  };

  const getOTPPages = (data) => {
    let OTPPages = 0;
    const mod = data.length % rowSize;
    if (mod > 0) {
      OTPPages = data.length / rowSize - mod / rowSize + 1;
    } else {
      OTPPages = data.length / rowSize;
    }
    return OTPPages;
  };

  /*const getOTPBatchPages = (data) => {
     let OTPBatchPages = 0;
    if (!data.hasOwnProperty('total')) {
      OTPBatchPages = 1;
    } else {
      OTPBatchPages = data.total;
    }
    return OTPBatchPages; 
  };*/

  const handlePagesPagination = (page) => {
    setState({ curPage: page });
    if (page > 0) {
      const sliceStart = page * rowSize;
      const sliceEnd = (page + 1) * rowSize;
      setState({
        slicedOTPData: OTPData.slice(sliceStart, sliceEnd),
      });
    } else {
      const sliceStart = 0;
      const sliceEnd = rowSize;
      setState({
        slicedOTPData: OTPData.slice(sliceStart, sliceEnd),
        checkedRows: [],
      });
    }
  };

  const handleBatchesPagination = (page) => {
    if (isApiCalled) {
      searchOTPDetails(searchInput, page);
    }
    getOTPDetails(page);
    setState({ curBatchPage: page });
  };

  const handleCheck = (rowId) => {
    if (checkedRows.includes(rowId)) {
      const updatedCheckedRows = checkedRows.filter((id) => id !== rowId);
      setState({ checkedRows: updatedCheckedRows });
      /* setCheckedRows(checkedRows.filter((id) => id !== rowId)); */
    } else {
      setState({ checkedRows: [...checkedRows, rowId] });
      /* setCheckedRows([...checkedRows, rowId]); */
    }
  };

  const handleSelectAll = (event) => {
    setState({ selectAll: event.target.checked });
    if (event.target.checked) {
      const selectedAll = slicedOTPData.map((data) => data.recipient);
      setState({ checkedRows: [...new Set([...checkedRows, ...selectedAll])] });
    } else {
      const updatedCheckedRows = checkedRows.filter(
        (id) => !slicedOTPData.some((data) => data.recipient === id)
      );
      setState({ checkedRows: updatedCheckedRows });
    }
  };

  return (
    <div className={styles.root_div}>
      <div>
        <PageHeader text='OTP Logs' />
        <div className={styles.search_area_block}>
          <div className={styles.user_input}>
            <RowPaginator
              handleRowPaginationChange={handleRowPaginationChange}
              rowSize={rowSize}
            />
            <div className={styles.right}>
              <div className={styles.search}>
                <Search
                  placeholder={'Mobile Number'}
                  /* onBtnPress={handleOTPNameSearch} */
                  onBtnPress={searchOTPDetails}
                  onClearPress={handleOTPClear}
                  onChange={searchOnChange}
                  validation={'mobileNo'}
                  searchInput={searchInput}
                  max={50}
                  handleValueFromParent
                  value={searchInput}
                />
              </div>
              <div className={styles.button_container}>
                <Button
                  onClick={handleDeleteOTPLogs}
                  className={styles.button}
                  variant='danger'
                  disabled={checkedRows.length === 0}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <CheckableDataTable
            /* totalData={OTPData} */
            tableData={slicedOTPData}
            tableheading={tableHeading}
            isSelected={isSelected}
            tag='otp-logs'
            fetchError={OTPLoadingError}
            notFoundMessage={OTPLoadingError}
            search_filter_loading={loading}
            searchFilterLoading={false}
            handleCheck={handleCheck}
            handleSelectAll={handleSelectAll}
            checkedRows={checkedRows}
            selectAll={selectAll}
          />
          {!OTPLoadingError && (
            <div className={styles.dualPaginateContainer}>
              <div className={styles.batches}>
                <Pagination
                  pageCount={OTPBatchPages}
                  onChange={handleBatchesPagination}
                  currentPage={0}
                />
                &nbsp;&nbsp;&nbsp;
                <span>
                  Batch {curBatchPage + 1} of {OTPBatchPages}
                </span>
              </div>
              <div className={styles.pages}>
                <span>
                  Page {curPage + 1} of {OTPPages}
                </span>
                &nbsp;&nbsp;&nbsp;
                <Pagination
                  pageCount={OTPPages}
                  onChange={handlePagesPagination}
                  currentPage={0}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(OTPLogs);
