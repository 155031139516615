import React from 'react';
import moment from 'moment';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './CheckableDataTable.module.scss';

const CheckableDataTable = ({
  /* totalData, */
  tableData,
  tableheading,
  tag,
  fetchError,
  search_filter_loading,
  notFoundMessage,
  handleCheck,
  handleSelectAll,
  checkedRows,
  handleOnClick,
}) => {
  const renderTableHeadings = (id) => {
    const renderHeading = (data, index) => {
      return (
        <th key={index} className={styles.table__heading} align='center'>
          {index === 0 && tableData.length > 0 && (
            <input
              className={styles.checkbox}
              type='checkbox'
              checked={tableData.every((rowData) =>
                checkedRows.includes(rowData[id])
              )}
              onChange={handleSelectAll}
            />
          )}
          {data}
        </th>
      );
    };

    return tableheading.column.map(renderHeading);
  };

  // onClickEvent: Boolean to control row click event propagation when clicking the checkbox
  const renderCheckboxColumn = (id, onClickEvent) => {
    return (
      <td>
        <div className={styles.checkbox__column}>
          <input
            className={styles.checkbox}
            type='checkbox'
            checked={checkedRows.includes(id)}
            onClick={
              onClickEvent ? (event) => handleCheck(id, event) : undefined
            }
          />
          <span className={styles.data}>{id}</span>
        </div>
      </td>
    );
  };

  const renderTableData = () => {
    switch (tag) {
      case 'ip-logs':
        return tableData.map((data) => {
          return renderIPDetails(data);
        });
      case 'otp-logs':
        return tableData.map((data) => {
          return renderOTPDetails(data);
        });
      case 'voucher-details':
        return tableData.map((data) => {
          return renderVoucherDetails(data);
        });

      case 'pending-purchases':
        return tableData.map((data) => {
          return renderPendingPurchases(data);
        });
      default:
        return;
    }
  };

  const renderTableHeader = () => {
    switch (tag) {
      case 'ip-logs':
        return renderTableHeadings('ipAddress');
      case 'otp-logs':
        return renderTableHeadings('recipient');
      case 'voucher-details':
      case 'pending-purchases':
        return renderTableHeadings('id');
      default:
        return;
    }
  };

  const renderIPDetails = (data) => {
    return (
      <tr
        className={styles.table_row}
        onClick={() => handleCheck(data.ipAddress)}
        key={data.ipAddress}
      >
        {renderCheckboxColumn(data.ipAddress, false)}
        <td>{data.count}</td>
        <td>{moment(data.blockedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
        <td>{moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
      </tr>
    );
  };

  const renderOTPDetails = (data) => {
    return (
      <tr
        className={styles.table_row}
        onClick={() => handleCheck(data.recipient)}
        key={data.recipient}
      >
        {renderCheckboxColumn(data.recipient, false)}
        <td>{data.time}</td>
        <td>{data.invalid}</td>
        <td>{data.sent}</td>
        <td>{moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
      </tr>
    );
  };

  const renderVoucherDetails = (data) => {
    return (
      <tr
        className={styles.table_row}
        onClick={() => handleOnClick(data.id)}
        key={data.id}
      >
        {renderCheckboxColumn(data.id, true)}
        <td>{data.sender}</td>
        <td>{moment(data.createdDate).format('YYYY-MM-DD HH:mm:ss')}</td>
        <td>{data.ownerName}</td>
        <td>
          {data.amount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </td>
        <td>{data.ownerMobile}</td>
      </tr>
    );
  };

  const renderPendingPurchases = (data) => {
    return (
      <tr
        className={styles.table_row}
        onClick={() => handleOnClick(data.id)}
        key={data.id}
      >
        {renderCheckboxColumn(data.id, true)}
        <td>{data.purchaseId}</td>
        <td>{moment(data.createdDate).format('YYYY-MM-DD HH:mm:ss')}</td>
        <td>{data.createdBy ? data.createdBy : data.senderName}</td>
        <td>
          {data.amount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </td>
      </tr>
    );
  };

  const renderNotFound = (content) => {
    return (
      <tr>
        <td
          colSpan={tableheading.column && tableheading.column.length}
          className='text-secondary bg-white text-center'
        >
          <span className='py-4'>{content}</span>
        </td>
      </tr>
    );
  };

  const renderTable = () => {
    return (
      <div className='w-100'>
        <Table striped bordered hover>
          <thead>
            <tr>{renderTableHeader()}</tr>
          </thead>
          <tbody>
            {search_filter_loading ? (
              <tr>
                <td
                  colSpan={tableheading.column && tableheading.column.length}
                  className='text-secondary bg-white text-center'
                >
                  <Spinner animation='border' />
                </td>
              </tr>
            ) : fetchError === '' && tableData.length > 0 ? (
              renderTableData()
            ) : notFoundMessage ? (
              renderNotFound(notFoundMessage)
            ) : (
              renderNotFound(`NO ${tag.toUpperCase()} FOUND`)
            )}
          </tbody>
        </Table>
      </div>
    );
  };

  return <div className={styles.root__div}>{renderTable()}</div>;
};

export default CheckableDataTable;
