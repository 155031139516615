import { takeEvery, all, put, call, delay } from 'redux-saga/effects';
import * as Moment from 'moment';

import { getUserDetails as getUserDetailsFromApi } from '../api/auth';
import { set, retrieve, AUTH_TOKEN, remove } from '../store/storage';
import {
  AUTH_SUCCESS,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  INITIATE_AUTH_TIMEOUT,
  INITIATE_LOGOUT,
  initiateLogout as initiateLogoutAction,
  getUserDataFailure as getUserDataFailureAction,
  getUserDetailsSuccess as getUserDataSuccessAction,
  initiateAuthTimeout as initiateAuthTimeoutAction,
  initiateLogoutSuccess as initiateLogoutSuccessAction,
} from '../actions/auth';
import { setUserDetails } from '../actions/user';

function* handleAuthSuccess({
  type,
  payload: { token, expirationInMilliseconds },
}) {
  const expirationDate = Moment().add(expirationInMilliseconds, 'ms').format();
  yield set(AUTH_TOKEN, { token, expirationDate });
  yield put(initiateAuthTimeoutAction({ expirationInMilliseconds }));
}

function* handleAuthenticationTimeout({
  type,
  payload: { expirationInMilliseconds },
}) {
  yield delay(expirationInMilliseconds);
  yield put(initiateLogoutAction());
}

function getUserInfoFromDetails(details) {
  const {
    merchantDetailsControllerResponses: merchantAdmins,
    coreSubMerchantAdmin: subMerchantAdmin,
    role,
  } = details;

  let users = [];
  let currentUser = null;

  if (merchantAdmins) {
    users = merchantAdmins;
    currentUser = merchantAdmins[0].merchant;
  } else if (subMerchantAdmin) {
    users = subMerchantAdmin;
    currentUser = subMerchantAdmin;
  }

  return { users, currentUser, role };
}

function* handleGetUserDetails({ type, payload }) {
  try {
    const obj = yield retrieve(AUTH_TOKEN);
    if (obj === null) {
      yield put(getUserDataFailureAction());
    } else {
      const { token, expirationDate } = obj;
      const expirationMoment = yield Moment(expirationDate);
      const currentMoment = yield Moment().add('10', 'm');
      if (expirationMoment.isBefore(currentMoment)) {
        yield remove(AUTH_TOKEN);
        yield put(getUserDataFailureAction());
      } else {
        const expirationInMilliseconds = expirationMoment.diff(
          currentMoment,
          'ms'
        );
        const response = yield call(getUserDetailsFromApi, token);
        const authData = {
          token,
          expirationInMilliseconds,
          details: {
            ...response,
          },
        };
        const userDetails = getUserInfoFromDetails(response);

        //  set user reducer
        yield put(setUserDetails(userDetails));
        yield put(getUserDataSuccessAction(authData));
      }
    }
  } catch (err) {
    yield put(getUserDataFailureAction());
  }
}

function* handleInitiateLogout({ type, payload }) {
  yield delay(2000);
  yield remove(AUTH_TOKEN);
  yield put(initiateLogoutSuccessAction());
}

function* watchAuthSaga() {
  yield all([
    takeEvery(AUTH_SUCCESS, handleAuthSuccess),
    takeEvery(GET_USER_DETAILS, handleGetUserDetails),
    takeEvery(INITIATE_AUTH_TIMEOUT, handleAuthenticationTimeout),
    takeEvery(GET_USER_DETAILS_SUCCESS, handleAuthenticationTimeout),
    takeEvery(INITIATE_LOGOUT, handleInitiateLogout),
  ]);
}

export default watchAuthSaga;
