export const EarningsRoles = {
  ThyagaSuperAdmin: 'ROLE_THYAGA_ADMINISTRATOR',
  ThyagaCustomerCare: 'ROLE_THYAGA_CUSTOMER_CARE',
  ThyagaMerchantController: 'ROLE_THYAGA_MERCHANT_CONTROLLER',
  MerchantAdmin: 'ROLE_MERCHANT_ADMINISTRATOR',
  SubMerchantAdmin: 'ROLE_SUB_MERCHANT_ADMINISTRATOR',
  MerchantRedemptions: 'ROLE_MERCHANT_REDEMPTIONS',
};

//test comment
