import React from 'react';

import styles from './DrawerSeparator.module.scss';

const DrawerSeparator = (props) => {
  const { text } = props;

  return (
    <div className={styles.root__div}>
      <span>{text}</span>
      <div className={styles.separator} />
    </div>
  );
};

export default DrawerSeparator;
