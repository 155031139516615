import React from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { extendVoucherValidity } from '../../api/Vouchers';
import DatePicker from 'react-datepicker';
import useComponentState from './../../utils/useComponentState';
import Spinner from 'react-bootstrap/Spinner';

const VoucherExtensionModal = (props) => {
  const [
    {
      success,
      date,
      renderDatePicker,
      validated,
      updatedExpiry,
      loading,
      extendError,
    },
    setState,
  ] = useComponentState({
    success: false,
    updatedExpiry: null,
    date: null,
    renderDatePicker: false,
    validated: false,
    loading: false,
    extendError: false,
  });

  const handleCloseModal = () => {
    setState({
      success: false,
      updatedExpiry: null,
      date: null,
      renderDatePicker: false,
      validated: false,
      loading: false,
      extendError: false,
    });
    props.handleClose();
  };

  const onExtensionChange = (data) => {
    if (data.target.value === 'CUSTOM') {
      setState({ renderDatePicker: true, date: null, updatedExpiry: null });
    } else {
      let newDate = new Date(props.voucherExpiryDate);
      if (data.target.value === '1DAY') {
        newDate.setDate(newDate.getDate() + 1);
      } else if (data.target.value === '1WEEK') {
        newDate.setDate(newDate.getDate() + 7);
      } else if (data.target.value === '2WEEK') {
        newDate.setDate(newDate.getDate() + 14);
      }
      // The date state is used to display new date on extension success message
      setState({
        updatedExpiry: data.target.value,
        renderDatePicker: false,
        date: newDate,
      });
    }
  };

  const handleOnSubmit = async () => {
    if (!updatedExpiry && !date) {
      setState({
        validated: true,
      });
    } else {
      try {
        setState({
          loading: true,
          extendError: false,
        });
        const request = {
          expireDate: updatedExpiry,
        };
        await extendVoucherValidity(props.voucherId, request, props.token);
        if (props.searchId) {
          props.searchVoucherById();
        } else {
          props.getVoucherDetails();
        }
        setState({
          success: true,
          validated: false,
          loading: false,
          renderDatePicker: false,
        });
      } catch (error) {
        setState({ extendError: true, loading: false });
        console.log(error);
      }
    }
  };

  const onDateChange = (date) => {
    date.setHours(23, 59, 59, 0);
    setState({
      date: date,
      updatedExpiry: moment(date).format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  return (
    <Modal
      show={props.showActivationModal}
      onHide={() => handleCloseModal()}
      backdrop='static'
      size='md'
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Extend Voucher</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!success ? (
          <Form validated={validated}>
            <div style={{ textAlign: 'center', margin: '0 auto 1rem' }}>
              <span>
                Proceed to extend the validity period of voucher{' '}
                <strong>{props.voucherId}</strong>
              </span>
            </div>
            <Row>
              <Col sm>
                <Form.Group>
                  <Form.Control
                    as='select'
                    name='country'
                    onChange={onExtensionChange}
                    required
                  >
                    <option value='' hidden>
                      Select period of extension
                    </option>
                    <option value='1DAY'>1 Day</option>
                    <option value='1WEEK'>1 Week</option>
                    <option value='2WEEK'>2 Weeks</option>
                    <option value='CUSTOM'>Custom date</option>
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>
                    Choose a period of extension
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        ) : (
          <div style={{ textAlign: 'center', margin: '0 auto 1rem' }}>
            <span>
              You have successfully extended the validity of the voucher{' '}
              <strong>{props.voucherId}</strong> until{' '}
              {moment(date).format('YYYY-MM-DD')}
            </span>
          </div>
        )}
        {renderDatePicker && (
          <Row>
            <Col sm>
              <Form.Group>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Label>Select a Date</Form.Label>
                  <DatePicker
                    style={{ marginBottom: '1rem' }}
                    selected={date}
                    onChange={(date) => onDateChange(date)}
                    dateFormat='dd/MM/yyyy'
                    name='selectedDate'
                    minDate={
                      new Date(
                        new Date(props.voucherExpiryDate).getTime() +
                          24 * 60 * 60 * 1000
                      )
                    }
                    className={
                      validated
                        ? !date
                          ? 'form-control border-danger'
                          : 'form-control border-success'
                        : 'form-control'
                    }
                    isClearable
                  />
                </div>
                {!date && validated && (
                  <small className='text-danger'>Date cannot be empty</small>
                )}
              </Form.Group>
            </Col>
          </Row>
        )}
        {extendError && (
          <div className='text-center'>
            <small className='text-danger'>
              Oops, something went wrong. Please try again
            </small>
          </div>
        )}
        <div style={{ paddingTop: '1rem' }}>
          <Button
            variant='dark'
            disabled={loading}
            onClick={success ? handleCloseModal : handleOnSubmit}
            block
          >
            {loading ? (
              <Spinner animation='border' />
            ) : success ? (
              'OKAY'
            ) : (
              'EXTEND VOUCHER'
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VoucherExtensionModal;
