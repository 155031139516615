import React from 'react';

import { formatCurrency } from '../../utils/currencyFormatter';
import { DashboardInfoCard, DashboardInfoCardList } from '../../components';

const DashboardSummary = ({
  totalRedemptionAmount,
  currentMonthRedemptionAmount,
  numberOfVouchers,
  averageRedemptionAmount,
}) => {
  const currentMonth = new Date().toLocaleString('default', { month: 'long' });
  return (
    <DashboardInfoCardList>
      <DashboardInfoCard
        label='Total Redemptions (LKR)'
        value={formatCurrency(totalRedemptionAmount)}
      />
      <DashboardInfoCard
        label={`${currentMonth} Redemptions (LKR)`}
        value={formatCurrency(currentMonthRedemptionAmount)}
      />
      {/* <DashboardInfoCard
        label='No. of Vouchers Redeemed'
        value={numberOfVouchers}
      /> */}
      <DashboardInfoCard
        label='Average Redemption (LKR)'
        value={formatCurrency(averageRedemptionAmount)}
      />
    </DashboardInfoCardList>
  );
};

export default DashboardSummary;
