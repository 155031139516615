import React, { Component } from 'react';

import { connect } from 'react-redux';
import FunnelIcon from '@rsuite/icons/Funnel';
import { ButtonToolbar, Drawer, IconButton } from 'rsuite';
import { Link } from 'react-router-dom';

import Dropdown from 'react-bootstrap/Dropdown';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
/* import Dropdown from 'react-bootstrap/Dropdown'; */

import DataTable from '../../components/DataTable/DataTable';
import {
  PageHeader,
  Pagination,
  NewSubMerchantModal,
  MerchantFilterModal,
} from '../../components';
import { getAllMerchant } from '../../api/merchant';
import { getSubMerchantsByMerchant } from '../../api/subMerchant';
import { addNewSubmerchant } from '../../api/subMerchant';
import Search from '../../components/Search/Search.component';

import './ViewMerchants.scss';

class ViewMerchants extends Component {
  state = {
    loading: true,
    tableData: [],
    tableheading: {
      header: 'MERCHANTS',
      column: ['ID', 'Name', 'Category', 'Status', 'Website', 'Contact'],
    },
    fetchError: '',
    dataToRender: [],
    slicedData: [],
    merchantPages: 0,
    total: 1,
    mainCurrent: 0,
    current: 0,
    merchantDrawer: false,
    submerchantsData: [],
    subMerchantTableheading: {
      header: 'SUB MERCHANTS',
      column: ['ID', 'Email', 'Mobile', 'Location', 'T'],
    },
    subMerchantFetchError: '',
    selectedMerchant: null,
    selectedMerchantId: null,
    showModal: false,
    email: '',
    location: '',
    merchantPin: '',
    validated: false,
    addError: null,
    addIsSuccess: false,
    addApiCalling: false,
    searchedMerchantMessage: null,
    pinError: null,
    pinType: 'password',
    emailError: null,
    slicedSubMerchants: [],
    subMerchantPage: 0,
    successMessage: null,
    showFilterModal: false,
    filterData: {
      byStatus: false,
      status: true,
    },
  };

  componentWillMount() {
    getAllMerchant(this.props.auth.token)
      .then((response) => {
        const sortedData = this.sortMerchantList(response);

        this.setState({
          tableData: sortedData,
          loading: false,
          merchantPages: this.getPageCount(sortedData),
          dataToRender: sortedData,
          slicedData: sortedData.slice(0, 10),
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }

  parseInt = (id) => {
    return parseInt(id.substring(0), 10) + 1;
  };

  sortMerchantList = (data) => {
    return data.sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1));
  };

  getPageCount = (data) => {
    let merchantPage = 0;

    const mod = data.length % 10;

    if (mod > 0) {
      merchantPage = data.length / 10 - mod / 10 + 1;
    } else {
      merchantPage = data.length / 10;
    }

    return merchantPage;
  };

  handleMerchantNameSearch = (name) => {
    if (name) {
      const item = this.state.tableData.filter((item) => {
        let itemData = item.name.toLowerCase();
        return itemData.indexOf(name.toLowerCase()) > -1;
      });

      this.setState({
        dataToRender: item,
        slicedData: item.slice(0, 10),
        merchantPages: this.getPageCount(item),
        searchedMerchantMessage: `found for name ${name}`,
      });
    }
  };

  handleMerchantSearch = (id) => {
    if (id) {
      const item = this.state.tableData.filter((item) => item.id === id);

      this.setState({
        dataToRender: item,
        slicedData: item.slice(0, 10),
        merchantPages: this.getPageCount(item),
        searchedMerchantMessage: `id found for ${id}`,
      });
    }
  };

  handlMerchantsclear = () => {
    this.setState({
      dataToRender: this.state.tableData,
      slicedData: this.state.tableData.slice(0, 10),
      merchantPages: this.getPageCount(this.state.tableData),
      searchedMerchantMessage: null,
    });
  };

  handleTextVisibility = () => {
    if (this.state.pinType === 'password') {
      this.setState({ pinType: 'text' });
    } else {
      this.setState({ pinType: 'password' });
    }
  };

  handleMerchantpagination = (page) => {
    if (page > 0) {
      const sliceStart = page * 10;
      const sliceEnd = (page + 1) * 10;
      this.setState({
        slicedData: this.state.dataToRender.slice(sliceStart, sliceEnd),
      });
    } else {
      const sliceStart = 0;
      const sliceEnd = 10;
      this.setState({
        slicedData: this.state.dataToRender.slice(sliceStart, sliceEnd),
      });
    }
  };

  handleSubMerchantPagination = (page) => {
    let sliceStart = 0;
    let sliceEnd = 10;
    if (page > 0) {
      sliceStart = page * 10;
      sliceEnd = (page + 1) * 10;
    }
    this.setState({
      slicedSubMerchants: this.state.submerchantsData.slice(
        sliceStart,
        sliceEnd
      ),
    });
  };

  handleOnClick = (id) => {
    getSubMerchantsByMerchant(id, this.props.auth.token)
      .then((response) => {
        var submerchantPage = 0;
        var mod = response.subMerchants.length % 10;
        if (mod > 0) {
          submerchantPage = response.subMerchants.length / 10 - mod / 10 + 1;
        } else {
          submerchantPage = response.subMerchants.length / 10;
        }
        this.setState({
          submerchantsData: response.subMerchants,
          merchantDrawer: true,
          selectedMerchant: this.getSelectedMerchant(id),
          selectedMerchantId: id,
          slicedSubMerchants: response.subMerchants.slice(0, 10),
          subMerchantPage: submerchantPage,
        });
      })
      .catch((err) => {
        this.setState({
          submerchantsData: [],
          merchantDrawer: true,
          selectedMerchant: this.getSelectedMerchant(id),
          slicedSubMerchants: [],
        });
      });
  };

  getSelectedMerchant = (id) => {
    var name = '';
    this.state.tableData.filter((data) => {
      if (data.id === id) {
        name = data.name;
      }
    });
    return name;
  };

  handleDrawShow = (boolean) => {
    this.setState({
      merchantDrawer: boolean,
    });
  };

  handleAddNewSubMerchantOnClick = () => {
    this.handleDrawShow(false);
    this.handleModalShow(true);
  };

  handleModalShow = (boolean) => {
    this.setState({
      showModal: boolean,
    });
    if (!boolean) {
      this.setState({
        email: '',
        location: '',
        merchantPin: '',
        addIsSuccess: false,
        validated: false,
        pinError: null,
        emailError: null,
      });
    }
  };

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: this.state[name].length === 0 ? value.trim() : value,
      emailError: null,
    });
  };

  handlePinOnChange = (event) => {
    this.setState({
      merchantPin: event.target.value.replace(/[^0-9\s]/g, '').trim(),
      pinError: null,
    });
  };

  validatedEmail = () => {
    const pattern =
      /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    const result = pattern.test(this.state.email);

    return result;
  };

  handleFilter = ({ byStatus, status }) => {
    let filteredMerchants = this.state.tableData;

    if (byStatus) {
      filteredMerchants = this.state.tableData.filter((merchant) =>
        status
          ? merchant.status.toLowerCase() === 'active'
          : merchant.status.toLowerCase() === 'inactive'
      );
    }

    this.setState({
      dataToRender: filteredMerchants,
      slicedData: filteredMerchants.slice(0, 10),
      merchantPages: this.getPageCount(filteredMerchants),
      showFilterModal: false,
      filterData: { byStatus, status },
      searchedMerchantMessage: filteredMerchants.length ? null : 'found',
    });
  };

  handleAddNewSubMerchant = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({
      validated: true,
      addApiCalling: true,
    });

    if (this.state.merchantPin.length === 0) {
      this.setState({
        pinError: 'Merchant pin cannot be empty',
        addApiCalling: false,
      });
    } else {
      if (!this.validatedEmail()) {
        this.setState({
          emailError: 'Enter a valid email address',
          addApiCalling: false,
        });
        return;
      }
      const request = {
        email: this.state.email,
        location: this.state.location,
        mangerPin: this.state.merchantPin,
        merchantId: this.state.selectedMerchantId,
      };
      try {
        const result = await addNewSubmerchant(request, this.props.auth.token);
        this.setState({
          addIsSuccess: true,
          addApiCalling: false,
          successMessage: result.message,
        });
      } catch (err) {
        this.setState({
          addError: 'Failed to add new subMerchant',
          addIsSuccess: false,
          addApiCalling: false,
        });
      }
    }
  };

  onTerminalClick = (terminal) => {
    const encodedTerminal = btoa(terminal);
    const url = `${process.env.REACT_APP_MERCHANT_PORTAL_URL}/redeem/${encodedTerminal}`;
    window.open(url, '_blank');
  };

  renderTerminalsDropdown = (terminals) => {
    return (
      <Dropdown style={{ cursor: 'pointer' }}>
        <Dropdown.Toggle as='div' className='dropdownToggle' id='dropdown' />
        <Dropdown.Menu>
          {terminals.map((terminal) => {
            const encodedTerminal = btoa(terminal);
            return (
              <Dropdown.Item onSelect={() => this.onTerminalClick(terminal)}>
                {terminal}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  renderSubMerchantsDrawer = () => {
    return (
      <div className='drawer_table'>
        <div className='drawer_search_area'>
          <span className='drawer_page_header'>
            Sub Merchants For {this.state.selectedMerchant}
          </span>
        </div>
        <div
          className={
            this.state.submerchantsData.length > 10
              ? 'drawer_table_content_page'
              : 'drawer_table_content'
          }
        >
          <DataTable
            tableData={this.state.slicedSubMerchants}
            tableheading={this.state.subMerchantTableheading}
            handleOnClick={() => {}}
            tag='subMerchants'
            fetchError={this.state.subMerchantFetchError}
            renderTerminalsDropdown={this.renderTerminalsDropdown}
          />
        </div>
        {this.state.submerchantsData.length > 10 && (
          <Pagination
            pageCount={this.state.subMerchantPage}
            onChange={this.handleSubMerchantPagination}
            currentPage={0}
          />
        )}
        <div className='drawer_addBtn'>
          <Button
            variant='dark'
            block
            onClick={this.handleAddNewSubMerchantOnClick}
          >
            Add New Sub Merchant
          </Button>
        </div>
      </div>
    );
  };

  /* renderTableDropdown = (id) => {
    return (
      <Dropdown>
        <Dropdown.Toggle className='dropdownToggle' id='dropdown-basic' />
        <Dropdown.Menu>
          <Dropdown.Item onSelect={() => this.handleOnClick(id)}>
            Show Sub Merchant
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={`edit-Merchant/${id}`}>
            Edit Merchant
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }; */

  render() {
    return (
      <div className='root__div'>
        {this.state.loading ? (
          <div className='spinner'>
            <Spinner animation='border' />
          </div>
        ) : (
          <div className='table'>
            <div className='search_area'>
              <PageHeader text='Merchants' />
              <div className='table_search'>
                <Search
                  placeholder='Merchant Name'
                  onBtnPress={this.handleMerchantNameSearch}
                  onClearPress={this.handlMerchantsclear}
                  max={10}
                />
                <Search
                  placeholder='Merchant ID'
                  onBtnPress={this.handleMerchantSearch}
                  onClearPress={this.handlMerchantsclear}
                  max={50}
                />
                <ButtonToolbar>
                  <IconButton
                    size='lg'
                    icon={<FunnelIcon />}
                    onClick={() => {
                      this.setState({ showFilterModal: true });
                    }}
                  />
                </ButtonToolbar>
              </div>
            </div>
            <DataTable
              tableData={this.state.slicedData}
              tableheading={this.state.tableheading}
              handleOnClick={this.handleOnClick}
              tag='merchants'
              fetchError={this.state.fetchError}
              notFoundMessage={
                this.state.dataToRender.length === 0 &&
                `No merchant ${this.state.searchedMerchantMessage}`
              }
              /* renderTableDropdown={this.renderTableDropdown} */
            />
            <Pagination
              pageCount={this.state.merchantPages}
              onChange={this.handleMerchantpagination}
              currentPage={0}
            />
          </div>
        )}

        <Drawer
          size='md'
          show={this.state.merchantDrawer}
          onHide={() => this.handleDrawShow(false)}
        >
          {this.renderSubMerchantsDrawer()}
        </Drawer>
        <NewSubMerchantModal
          {...this.state}
          addNewSubMerchant={this.handleAddNewSubMerchant}
          toggleModalVisibility={this.handleModalShow}
          changeInputValue={this.handleOnChange}
          changePin={this.handlePinOnChange}
          toggleTextVisibility={this.handleTextVisibility}
        />
        <MerchantFilterModal
          show={this.state.showFilterModal}
          filterData={this.state.filterData}
          onFilter={this.handleFilter}
          toggleModalVisibility={() =>
            this.setState((prevState) => ({
              showFilterModal: !prevState.showFilterModal,
            }))
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(ViewMerchants);
