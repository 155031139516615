import { GET, POST, POST_FORM_DATA } from './core';

export const getEndpointWithPrefix = (endpoint) => {
  return `/voucher/${endpoint}`;
};

export const searchVouchers = (request, token) => {
  const endpoint = getEndpointWithPrefix('search');
  return POST(endpoint, request, token).then((response) => {
    return response;
  });
};

export function extendVoucherValidity(voucherCode, request, token) {
  const endpoint = getEndpointWithPrefix(`extend?voucherCode=${voucherCode}`);
  return POST(endpoint, request, token).then((response) => {
    return response;
  });
}

export const activateVoucher = (request, token) => {
  const endpoint = getEndpointWithPrefix('activate');
  return POST(endpoint, request, token).then((response) => {
    return response;
  });
};

export const getQrPdf = (request, token) => {
  const endpoint = getEndpointWithPrefix('generateQR/qrList');
  return POST(endpoint, request, token).then((response) => {
    return response;
  });
};

export const listBulkComplete = (request, token) => {
  const endpoint = getEndpointWithPrefix('bulk-complete-list');
  return POST(endpoint, request, token).then((response) => {
    return response;
  });
};

export const uploadBulkOrder = (uploadedFile, token) => {
  const endpoint = getEndpointWithPrefix('bulk-order');
  return POST_FORM_DATA(endpoint, uploadedFile, token);
};

export const getVouchersByStatus = (
  token,
  statusKey,
  currentPage,
  pageSize
) => {
  const endpoint = getEndpointWithPrefix(
    `getVouchers?statusKey=${statusKey}&page=${currentPage}&pageSize=${pageSize}&sortBy=createdDate&order=desc`
  );
  return GET(endpoint, token);
};

export const getPendingPurchases = (token, pageSize, currentPage) => {
  const endpoint = getEndpointWithPrefix(
    `getPendingVouchers/?page=${currentPage}&pageSize=${pageSize}&sortBy=id&order=desc`
  );
  return GET(endpoint, token);
};

export const searchPendingPurchases = (
  token,
  searchKey,
  currentPage,
  pageSize,
  dates
) => {
  let query = `search-pending-vouchers/?searchKey=${searchKey}&page=${currentPage}&pageSize=${pageSize}&sortBy=id&order=asc`;

  if (dates.startDate && dates.endDate) {
    query = query.concat(
      `&startDate=${dates.startDate}&endDate=${dates.endDate}`
    );
  }

  const endpoint = getEndpointWithPrefix(query);
  return GET(endpoint, token);
};

export const getTransactions = (token, voucherId) => {
  let query = `transaction/${voucherId}`;
  const endpoint = getEndpointWithPrefix(query);
  return GET(endpoint, token);
};
