import React from 'react';
import styles from './RowPaginator.module.scss';

const RowPaginator = ({ handleRowPaginationChange, rowSize }) => {
  return (
    <>
      <label className={styles.label}>
        Show
        <select
          value={rowSize}
          onChange={handleRowPaginationChange}
          aria-controls='example'
          className={`form-control ${styles.select}`}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
        rows
      </label>
    </>
  );
};

export default RowPaginator;
