import React from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';

const NewSubMerchantModal = (props) => {
  const renderInputField = (name, label, value, onChange, max) => {
    return (
      <Form.Group as={Row}>
        <Col>
          <Form.Label>{label}</Form.Label>
          <Form.Control
            name={name}
            value={value}
            onChange={onChange}
            maxLength={max}
            required
            autoComplete='off'
          />
          <Form.Control.Feedback type='invalid' className='error_msg'>
            {label} cannot be empty
          </Form.Control.Feedback>
          {name === 'email' && props.emailError && (
            <small className='error_msg'>{props.emailError}</small>
          )}
        </Col>
      </Form.Group>
    );
  };

  const renderInputGroup = (
    name,
    label,
    value,
    onChange,
    max,
    onClick,
    type
  ) => {
    return (
      <Form.Group as={Row}>
        <Col>
          <Form.Label>{label}</Form.Label>
          <InputGroup>
            <Form.Control
              type={type}
              name={name}
              value={value}
              onChange={onChange}
              required
              maxLength={max}
            />
            <InputGroup.Append onClick={onClick}>
              <InputGroup.Text className='password-toggle__span'>
                <i
                  className={type === 'text' ? 'fa fa-eye-slash' : 'fa fa-eye'}
                ></i>
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
          {props.pinError && (
            <small className='error_msg'>{props.pinError}</small>
          )}
        </Col>
      </Form.Group>
    );
  };

  return (
    <Modal
      show={props.showModal}
      onHide={() => {
        props.toggleModalVisibility(false);
      }}
      backdrop='static'
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{`Add New Sub Merchant for ${props.selectedMerchant}`}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          noValidate
          validated={props.validated}
          onSubmit={props.addNewSubMerchant}
        >
          {renderInputField(
            'email',
            'Email',
            props.email,
            props.changeInputValue,
            320
          )}
          {renderInputField(
            'location',
            'Location',
            props.location,
            props.changeInputValue,
            255
          )}
          {renderInputGroup(
            'merchantPin',
            'Merchant Pin',
            props.merchantPin,
            props.changePin,
            5,
            props.toggleTextVisibility,
            props.pinType
          )}
          <Button variant='dark' type='submit' block>
            {props.addApiCalling ? 'Loading' : 'ADD'}
          </Button>
        </Form>
      </Modal.Body>
      <div className='successMessage'>
        {props.addIsSuccess && (
          <span className='success_msg'>{props.successMessage}</span>
        )}
      </div>
    </Modal>
  );
};

export default NewSubMerchantModal;
