import React, { useEffect } from 'react';
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';

import { SideBar, DrawerToggle, Loading } from './components';
import useComponentState from './utils/useComponentState';
import { Login } from './screens';
import ForgotPassword from './screens/forgotPassword/ForgotPassword.screen';
import routes from './routes';
import { getUserDetails as getUserDetailsAction } from './actions/auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styles from './Root.module.scss';

const Root = (props) => {
  const [state, setState] = useComponentState({
    drawerOpen: false,
  });

  const { drawerOpen } = state;
  const {
    getUserDetails,
    auth: { userDetailsLoading, logoutLoading, auth },
  } = props;

  useEffect(() => {
    getUserDetails();
    //eslint-disable-next-line
  }, []);

  const handleDrawerToggleClick = () => {
    setState({ drawerOpen: !drawerOpen });
  };

  const renderLoading = () => {
    return <Loading text='Loading' />;
  };

  const renderLoggingOut = () => {
    return <Loading text='Logging out' />;
  };

  const renderAuthRoutes = () => {
    const renderedRoutes = routes.map((route) => {
      const { children, path, component: Component } = route;
      const hasChildren = children && children.length > 0 ? true : false;

      return hasChildren ? (
        children.map(
          ({ name: childName, path: childPath, component: ChildComponent }) => {
            return (
              <Route exact path={`${path}${childPath}`} key={path}>
                <ChildComponent />
              </Route>
            );
          }
        )
      ) : (
        <Route exact path={`${path}`} key={path}>
          <Component />
        </Route>
      );
    });
    return (
      <Router>
        <ToastContainer
          position='bottom-right'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        />
        <SideBar isOpen={drawerOpen} onClick={handleDrawerToggleClick} />
        <main className={styles.main}>
          <DrawerToggle onClick={handleDrawerToggleClick}></DrawerToggle>
          <Switch>
            {renderedRoutes}
            <Route render={() => <Redirect to={{ pathname: '/' }} />} />
          </Switch>
        </main>
      </Router>
    );
  };

  const renderUnauthRoutes = () => {
    return (
      <Router>
        <main className={styles.main}>
          <Switch>
            <Route exact path='/forgotpassword'>
              <ForgotPassword></ForgotPassword>
            </Route>
            <Route exact path=''>
              <Login />
            </Route>
          </Switch>
        </main>
      </Router>
    );
  };
  const routesToRender = userDetailsLoading
    ? renderLoading()
    : logoutLoading
    ? renderLoggingOut()
    : !auth
    ? renderUnauthRoutes()
    : renderAuthRoutes();

  return <div className={styles.root__div}>{routesToRender}</div>;
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserDetails: () => {
      dispatch(getUserDetailsAction());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
