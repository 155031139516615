import React from 'react';

const EditMerchant = () => {
  return (
    <div className='d-flex align-items-center justify-content-center vh-100'>
      Work in progress
    </div>
  );
};

export default EditMerchant;

// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { compose } from 'redux';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import { withRouter } from 'react-router-dom';
// import Button from 'react-bootstrap/Button';
// import Card from 'react-bootstrap/Card';
// import Form from 'react-bootstrap/Form';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import Tabs from 'react-bootstrap/Tabs';
// import Tab from 'react-bootstrap/Tab';
// import styles from './editMerchant.module.scss';
// import {
//   editMerchant,
//   getMerchantCategories,
//   getMerchantDetails,
// } from '../../api/merchant';
// import { getMerchantTags } from '../../api/app';
// import CancelAlertPopup from '../../components/CancelAlertPopup/CancelAlertPopup';
// import { TagPill } from '../../components';
// import AddCategory from '../addCategory/AddCategory';

// class EditMerchant extends Component {
//   state = {
//     validated: false,
//     name: '',
//     address: '',
//     tel: '',
//     categoryList: [],
//     category: 'Fashion',
//     status: 'Active',
//     website: '',
//     imgUrl: '',
//     thumbnail: '',
//     description: '',
//     termsAndconditions: 'Merchant Terms & Conditions applied',
//     statusArray: ['Active', 'Inactive'],
//     accountName: '',
//     accountNumber: '',
//     confirmAccountNumber: '',
//     bankName: '',
//     email: '',
//     branchName: '',
//     commission: '',
//     apiError: null,
//     emailError: null,
//     loading: false,
//     isSuccess: false,
//     key: 'primary',
//     glName: '',
//     glType: '',
//     totalBalance: '',
//     glDescription: '',
//     confirmError: null,
//     primaryFormValidated: false,
//     financeFormValidated: false,
//     primaryFormValidity: false,
//     financeFormValidity: false,
//     showCancelPopup: false,
//     amountError: null,
//     mobileNoError: null,
//     confirmNavigation: null,
//     cancelNavigation: null,
//     commissionError: '',
//     disableCancel: false,
//     showNewPopup: false,
//     tags: [],
//     selectedTags: [],
//     merchantDetails: {},
//     id: '',
//   };

//   componentDidMount() {
//     const {
//       match: {
//         params: { id },
//       },
//     } = this.props;
//     this.setState({ id });
//     this.getDetails(id);
//     this.getTags();
//     this.getCategories();
//   }

//   getDetails = (id) => {
//     getMerchantDetails(this.props.auth.token, id).then((response) => {
//       this.setState({
//         merchantDetails: response,
//         name: response.name,
//         address: response.address,
//         tel: response.tel,
//         category: response.category,
//         status: response.status,
//         website: response.website,
//         imgUrl: response.imgUrl,
//         thumbnail: response.thumbnail,
//         description: this.parseResponse(response.description),
//         termsAndconditions: this.parseResponse(response.termsAndConditions),
//         accountName: response.accountName,
//         accountNumber: response.accountNumber,
//         confirmAccountNumber: response.accountNumber,
//         bankName: response.bankName,
//         email: response.financeEmail,
//         branchName: response.bankBranchCode,
//         commission: response.commission,
//         glName: response.glName,
//         glType: response.glType,
//         totalBalance: response.totalBalance,
//         glDescription: response.glDescription,
//         selectedTags: response.tags.map((tag) => tag.id),
//       });
//     });
//   };

//   getTags = () => {
//     getMerchantTags(this.props.auth.token).then((response) => {
//       /* const tags = response.map((tag) => tag.name); */
//       this.setState({ tags: response });
//     });
//   };

//   getCategories = () => {
//     getMerchantCategories(this.props.auth.token).then((response) => {
//       this.setState({
//         categoryList: response,
//         category: response[0],
//       });
//     });
//   };

//   parseResponse = (response) => {
//     const parser = new DOMParser();

//     const formattedDescription = parser
//       .parseFromString(
//         response.replace(/<\/span>/g, '</span>###BREAK###'),
//         'text/html'
//       )
//       .body.textContent.replace(/###BREAK###/g, '\n');

//     return formattedDescription;
//   };

//   handlePrimaryFormValidation = (event) => {
//     const form = event.currentTarget;
//     event.preventDefault();
//     this.setState({
//       primaryFormValidated: true,
//     });

//     if (form.checkValidity()) {
//       if (this.validateMobileNumber()) {
//         this.handleKeyState('finance');
//         this.setState({
//           primaryFormValidity: true,
//         });
//       } else {
//         this.setState({
//           mobileNoError: 'Please enter a valid mobile number',
//         });
//       }
//     }
//   };

//   handleFinanceFormValidation = (event) => {
//     const form = event.currentTarget;
//     event.preventDefault();
//     this.setState({
//       financeFormValidated: true,
//     });

//     if (!this.confirmAccountNumbers()) {
//       this.setState({
//         confirmError: 'Entered account numbers do not match',
//       });
//       return;
//     }
//     if (this.state.commission[this.state.commission.length - 1] === '.') {
//       this.setState({
//         commissionError: 'Enter a valid commission',
//       });
//       return;
//     }
//     if (form.checkValidity()) {
//       if (this.validatedEmail()) {
//         this.handleKeyState('gl');
//         this.setState({
//           financeFormValidity: true,
//         });
//       }
//     }
//   };

//   handleSubmit = (event) => {
//     const form = event.currentTarget;
//     event.preventDefault();
//     if (!form.checkValidity()) {
//       event.preventDefault();
//       event.stopPropagation();
//       this.setState({
//         validated: true,
//         isSuccess: false,
//         apiError: null,
//       });
//       return;
//     }

//     if (isNaN(this.state.totalBalance)) {
//       this.setState({
//         amountError: 'Please enter a valid total balance',
//       });
//       return;
//     }

//     if (this.validatedEmail()) {
//       this.setState({ loading: true });

//       const description = `<p class="ql-align-justify"><span style="font-size: 11pt; background-color: rgb(255, 255, 255); font-family: Poppins, sans-serif; color: rgb(33, 37, 41);">${this.state.description}</span></p><p><br></p>`;
//       const termsAndconditions = `<p><br></p><p class="ql-align-justify"><span style="font-size: 11pt; font-family: Poppins, sans-serif; color: rgb(33, 37, 41);">${this.state.termsAndconditions}</span></p><p><br></p>`;

//       const request = {
//         name: this.state.name,
//         address: this.state.address,
//         tel: this.state.tel,
//         category: this.state.category,
//         status: this.state.status,
//         website: this.state.website,
//         imgUrl: this.state.imgUrl,
//         thumbnail: this.state.thumbnail,
//         description: description,
//         termsAndConditions: termsAndconditions,
//         accountName: this.state.accountName,
//         accountNumber: this.state.accountNumber,
//         bankName: this.state.bankName,
//         email: this.state.email,
//         bankBranchCode: this.state.branchName,
//         commission: this.state.commission,
//         glName: this.state.glName,
//         glType: this.state.glType,
//         totalBalance: this.state.totalBalance,
//         glDescription: this.state.glDescription,
//         tagIds: this.state.selectedTags,
//       };

//       editMerchant(request, this.props.auth.token, this.state.id)
//         .then((response) => {
//           this.setState({
//             isSuccess: true,
//             loading: false,
//             apiError: null,
//           });
//         })
//         .catch((err) => {
//           this.setState({
//             apiError: err,
//             loading: false,
//             isSuccess: false,
//           });
//         });
//     }
//   };

//   confirmAccountNumbers = () => {
//     return this.state.confirmAccountNumber === this.state.accountNumber;
//   };

//   handleQuillChange = (content, name) => {
//     this.setState({
//       [name]: content,
//     });
//   };

//   handleInputChange = (event) => {
//     let { name, value } = event.target;
//     let item = this.state[name];
//     if (item.length === 0) {
//       value = value.trim();
//     }
//     this.setState({
//       [name]: value,
//       disableCancel: false,
//     });

//     if (event.target.name === 'email') {
//       this.setState({
//         emailError: null,
//       });
//     }
//     if (event.target.name === 'confirmAccountNumber') {
//       this.setState({
//         confirmError: null,
//       });
//     }
//   };

//   handleDropDownChange = (event, name) => {
//     let { value } = event.target;
//     this.setState({
//       [name]: value,
//     });
//   };

//   handleNumberChange = (event) => {
//     let { name, value } = event.target;
//     let item = this.state[name];
//     if (item.length === 0) {
//       value = value.trim();
//     }
//     if (
//       name === 'accountNumber' &&
//       value !== this.state.merchantDetails.accountNumber
//     ) {
//       this.setState({
//         confirmAccountNumber: '',
//       });
//     }
//     this.setState({
//       [name]: value.replace(/[^0-9\s]/g, ''),
//       confirmError: null,
//       mobileNoError: null,
//     });
//   };

//   handleAmountOnChange = (event) => {
//     let { name, value } = event.target;
//     let item = this.state[name];
//     if (item.length === 0) {
//       value = value.trim();
//     }
//     if (value.length === 1 && value === '.') {
//       value = '0.';
//     }
//     if (value.split('').filter((letter) => letter === '.').length <= 1) {
//       this.setState({
//         [name]: value.replace(/[^0-9.\s]/g, ''),
//         amountError: null,
//       });
//     }
//   };

//   validateMobileNumber = () => {
//     const tel = this.state.tel;
//     if (tel.length < 10) {
//       return false;
//     } else {
//       const reg = /^[0]\d{9}$/;
//       return reg.test(this.state.tel);
//     }
//   };

//   validatedEmail = () => {
//     const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     const result = pattern.test(this.state.email);

//     if (!result) {
//       this.setState({
//         emailError: 'Enter a valid email address',
//       });
//     }
//     return result;
//   };

//   handleNext = (event) => {
//     const key = this.state.key;
//     if (key === 'primary') {
//       this.handlePrimaryFormValidation(event);
//     } else if (key === 'finance') {
//       this.handleFinanceFormValidation(event);
//     } else {
//       this.handleKeyState('primary');
//     }
//   };

//   handleKeyState = (key) => {
//     this.setState({
//       key,
//     });
//   };

//   handleCancel = () => {
//     this.setState({
//       showCancelPopup: !this.state.showCancelPopup,
//     });
//   };

//   handleClear = () => {
//     this.setState({
//       name: '',
//       address: '',
//       tel: '',
//       category: 'Fashion',
//       status: 'Active',
//       website: '',
//       imgUrl: '',
//       thumbnail: '',
//       description: '',
//       termsAndconditions: 'Merchant Terms & Conditions applied',
//       accountName: '',
//       accountNumber: '',
//       bankName: '',
//       email: '',
//       branchName: '',
//       commission: '',
//       glName: '',
//       glType: '',
//       totalBalance: '',
//       glDescription: '',
//       confirmAccountNumber: '',
//       financeFormValidated: false,
//       primaryFormValidated: false,
//       validated: false,
//       confirmError: null,
//       mobileNoError: null,
//       amountError: null,
//       emailError: null,
//     });
//     this.handleCancel();
//   };

//   handleNewOnClick = () => {
//     this.setState({
//       showNewPopup: !this.state.showNewPopup,
//     });
//   };

//   capitalize = (s) => {
//     if (typeof s !== 'string') return '';
//     return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
//   };

//   renderApiResponse = (message) => {
//     return (
//       <Card className={styles.card_footer}>
//         <Card.Body>
//           <span
//             className={
//               this.state.isSuccess ? styles.success_msg : styles.error_msg
//             }
//           >
//             {message}
//           </span>
//         </Card.Body>
//       </Card>
//     );
//   };

//   renderQuillText = (name, label, value, onChange, max) => {
//     return (
//       <Form.Group as={Row}>
//         <Col>
//           <Form.Label>{label}</Form.Label>
//           <ReactQuill
//             theme='snow'
//             name={name}
//             value={value}
//             onChange={(content) => onChange(content, name)}
//             required
//             maxLength={max}
//           />
//         </Col>
//       </Form.Group>
//     );
//   };

//   renderTextArea = (name, label, value, onChange, max) => {
//     return (
//       <Form.Group as={Row}>
//         <Col>
//           <Form.Label>{label}</Form.Label>
//           <Form.Control
//             aria-label={label}
//             aria-describedby='basic-addon2'
//             name={name}
//             value={value}
//             onChange={onChange}
//             required
//             as='textarea'
//             rows={3}
//             maxLength={max}
//             placeholder={`Enter ${label.toLowerCase()}`}
//           />
//           <Form.Control.Feedback type='invalid' className={styles.error_msg}>
//             {this.capitalize(label)} cannot be empty
//           </Form.Control.Feedback>
//         </Col>
//       </Form.Group>
//     );
//   };

//   renderDropDown = (name, label, data, value, onChange) => {
//     return (
//       <Form.Group as={Row}>
//         <Col>
//           <Form.Label>{label}</Form.Label>
//           <select
//             id='x-select'
//             class='form-control form-control-sm custom-select'
//             onClick={(event) => onChange(event, name)}
//             name={name}
//           >
//             {data &&
//               data.map((item, index) => (
//                 <option selected={item === value} key={index} value={item}>
//                   {item}
//                 </option>
//               ))}
//           </select>
//           {name === 'category' && (
//             <span
//               className={styles.new_category_link}
//               onClick={this.handleNewOnClick}
//             >
//               Add Category
//             </span>
//           )}
//         </Col>
//       </Form.Group>
//     );
//   };

//   renderInputField = (name, label, value, onChange, max) => {
//     return (
//       <Form.Group as={Row}>
//         <Col>
//           <Form.Label>{label}</Form.Label>
//           <Form.Control
//             name={name}
//             value={value}
//             onChange={onChange}
//             maxLength={max}
//             required={name !== 'commission'}
//             placeholder={
//               name === 'imgUrl'
//                 ? 'https://example.com/sample_image.png'
//                 : `Enter ${label.toLowerCase()}`
//             }
//           />
//           <Form.Control.Feedback type='invalid' className={styles.error_msg}>
//             {name === 'email'
//               ? 'Email address cannot be empty'
//               : `${this.capitalize(label)} cannot be empty`}
//           </Form.Control.Feedback>
//           {name === 'email' && this.state.emailError
//             ? this.renderAdditionalErrorMessage(this.state.emailError)
//             : null}
//           {name === 'totalBalance' && this.state.amountError
//             ? this.renderAdditionalErrorMessage(this.state.amountError)
//             : null}
//           {name === 'tel' && this.state.mobileNoError
//             ? this.renderAdditionalErrorMessage(this.state.mobileNoError)
//             : null}
//           {name === 'confirmAccountNumber' && this.state.confirmError
//             ? this.renderAdditionalErrorMessage(this.state.confirmError)
//             : null}
//           {name === 'commission' && this.state.commissionError
//             ? this.renderAdditionalErrorMessage(this.state.commissionError)
//             : null}
//         </Col>
//       </Form.Group>
//     );
//   };

//   renderAdditionalErrorMessage = (err) => {
//     return <small className={styles.error_msg}>{err}</small>;
//   };

//   renderPrimaryForm = () => {
//     return (
//       <Form
//         noValidate
//         validated={this.state.primaryFormValidated}
//         onSubmit={this.handleNext}
//       >
//         <Row>
//           <Col>
//             {this.renderInputField(
//               'name',
//               'Merchant Name',
//               this.state.name,
//               this.handleInputChange,
//               20
//             )}
//           </Col>
//           <Col>
//             {this.renderInputField(
//               'address',
//               'Address',
//               this.state.address,
//               this.handleInputChange,
//               320
//             )}
//           </Col>
//         </Row>

//         <Row>
//           <Col>
//             {this.renderInputField(
//               'tel',
//               'Mobile Number',
//               this.state.tel,
//               this.handleNumberChange,
//               10
//             )}
//           </Col>
//           <Col>
//             {this.renderInputField(
//               'website',
//               'Website',
//               this.state.website,
//               this.handleInputChange,
//               320
//             )}
//           </Col>
//         </Row>

//         <Row>
//           <Col>
//             {this.renderInputField(
//               'imgUrl',
//               'Image URL',
//               this.state.imgUrl,
//               this.handleInputChange,
//               320
//             )}
//           </Col>
//           <Col>
//             {this.renderInputField(
//               'thumbnail',
//               'Thumbnail',
//               this.state.thumbnail,
//               this.handleInputChange,
//               320
//             )}
//           </Col>
//         </Row>

//         <Row>
//           <Col>
//             {this.renderDropDown(
//               'status',
//               'Status',
//               this.state.statusArray,
//               this.state.status,
//               this.handleDropDownChange
//             )}
//           </Col>
//           <Col>
//             {this.renderDropDown(
//               'category',
//               'Category',
//               this.state.categoryList,
//               this.state.category,
//               this.handleDropDownChange
//             )}
//           </Col>
//         </Row>
//         <Row>
//           <Col>
//             <Form.Group as={Row}>
//               <Col>
//                 <Form.Label>Choose Merchant Tag(s)</Form.Label>
//                 <div className={styles.category__list}>
//                   {this.state.tags.map((tag, index) => (
//                     <TagPill
//                       key={index}
//                       id={tag.id}
//                       title={tag.name}
//                       selected={this.state.selectedTags.includes(tag.id)}
//                       onSelect={(key) => {
//                         const updatedTagList = this.state.selectedTags.includes(
//                           key
//                         )
//                           ? this.state.selectedTags.filter((id) => id !== key)
//                           : [...this.state.selectedTags, key];
//                         this.setState({
//                           selectedTags: updatedTagList,
//                         });
//                       }}
//                     />
//                   ))}
//                 </div>
//                 {/* <Form.Control.Feedback type='invalid'>
//                   Atleast one tag should be selected
//                 </Form.Control.Feedback> */}
//               </Col>
//             </Form.Group>
//           </Col>
//         </Row>

//         <Row>
//           <Col>
//             {this.renderQuillText(
//               'description',
//               'Description',
//               this.state.description,
//               this.handleQuillChange,
//               350
//             )}
//           </Col>
//         </Row>

//         <Row>
//           <Col>
//             {this.renderQuillText(
//               'termsAndconditions',
//               'Terms and Conditions',
//               this.state.termsAndconditions,
//               this.handleQuillChange,
//               350
//             )}
//           </Col>
//         </Row>

//         {this.renderBtnFooter()}
//       </Form>
//     );
//   };

//   renderFinanceForm = () => {
//     return (
//       <Form
//         noValidate
//         validated={this.state.financeFormValidated}
//         onSubmit={this.handleNext}
//       >
//         <Row>
//           <Col>
//             {this.renderInputField(
//               'accountName',
//               'Account Name',
//               this.state.accountName,
//               this.handleInputChange,
//               20
//             )}
//           </Col>
//         </Row>

//         <Row>
//           <Col>
//             {this.renderInputField(
//               'accountNumber',
//               'Account Number',
//               this.state.accountNumber,
//               this.handleNumberChange,
//               20
//             )}
//           </Col>
//           <Col>
//             {this.renderInputField(
//               'confirmAccountNumber',
//               'Confirm Account Number',
//               this.state.confirmAccountNumber,
//               this.handleNumberChange,
//               20
//             )}
//           </Col>
//         </Row>

//         <Row>
//           <Col>
//             {this.renderInputField(
//               'bankName',
//               'Bank Name',
//               this.state.bankName,
//               this.handleInputChange,
//               20
//             )}
//           </Col>
//         </Row>

//         <Row>
//           <Col>
//             {this.renderInputField(
//               'branchName',
//               'Branch Name',
//               this.state.branchName,
//               this.handleInputChange,
//               20
//             )}
//           </Col>
//         </Row>

//         <Row>
//           <Col>
//             {this.renderInputField(
//               'email',
//               'Email Address',
//               this.state.email,
//               this.handleInputChange,
//               320
//             )}
//           </Col>
//           <Col>
//             {this.renderInputField(
//               'commission',
//               'Commission',
//               this.state.commission,
//               this.handleAmountOnChange,
//               10
//             )}
//           </Col>
//         </Row>

//         {this.renderBtnFooter()}
//       </Form>
//     );
//   };

//   renderGLForm = () => {
//     return (
//       <Form
//         noValidate
//         validated={this.state.validated}
//         onSubmit={this.handleSubmit}
//       >
//         <Row>
//           <Col>
//             {this.renderInputField(
//               'glName',
//               'Name',
//               this.state.glName,
//               this.handleInputChange,
//               50
//             )}
//           </Col>
//         </Row>

//         <Row>
//           <Col>
//             {this.renderInputField(
//               'glType',
//               'Type',
//               this.state.glType,
//               this.handleInputChange,
//               20
//             )}
//           </Col>
//         </Row>

//         <Row>
//           <Col>
//             {this.renderInputField(
//               'totalBalance',
//               'Total Balance',
//               this.state.totalBalance,
//               this.handleAmountOnChange,
//               10
//             )}
//           </Col>
//         </Row>

//         <Row>
//           <Col>
//             {this.renderTextArea(
//               'glDescription',
//               'Description',
//               this.state.glDescription,
//               this.handleInputChange,
//               350
//             )}
//           </Col>
//         </Row>

//         <div className={styles.form_footer}>
//           <Button
//             variant='secondary'
//             onClick={this.handleCancel}
//             className={styles.footer_btn}
//             disabled={this.state.disableCancel}
//           >
//             Cancel{' '}
//           </Button>
//           <Button variant='dark' type='submit' className={styles.next_btn}>
//             {this.state.loading ? 'Loading' : 'Submit'}
//           </Button>
//         </div>

//         {this.state.isSuccess
//           ? this.renderApiResponse('Merchant Updated Successfully')
//           : this.state.apiError
//           ? this.renderApiResponse('Failed to Update Merchant')
//           : null}
//       </Form>
//     );
//   };

//   renderTabs = () => {
//     return (
//       <Tabs
//         id='controlled-tab-example'
//         activeKey={this.state.key}
//         onSelect={(k) => {
//           this.setState({ key: k });
//         }}
//       >
//         <Tab eventKey='primary' title='Primary Details' />
//         <Tab eventKey='finance' title='Finance Details' />
//         <Tab eventKey='gl' title='GL Account Details' />
//       </Tabs>
//     );
//   };

//   renderBtnFooter = () => {
//     return (
//       <div className={styles.form_footer}>
//         <Button
//           variant='secondary'
//           onClick={this.handleCancel}
//           className={styles.footer_btn}
//           disabled={this.state.disableCancel}
//         >
//           {' '}
//           Cancel
//         </Button>
//         <Button variant='dark' type='submit' className={styles.footer_btn}>
//           {' '}
//           Next{' '}
//         </Button>
//       </div>
//     );
//   };

//   render() {
//     return (
//       <div className={styles.div_root}>
//         <Card>
//           <Card.Header as='h5'>
//             <Col className={styles.tab}>{this.renderTabs()}</Col>
//           </Card.Header>
//           <Col className={styles.form_root}>
//             {this.state.key === 'primary'
//               ? this.renderPrimaryForm()
//               : this.state.key === 'finance'
//               ? this.renderFinanceForm()
//               : this.renderGLForm()}
//           </Col>
//         </Card>
//         <CancelAlertPopup
//           show={this.state.showCancelPopup}
//           alertMessage='Are you sure you want to cancel ?'
//           handleYesBtnClick={this.handleClear}
//           handleNoBtnClick={this.handleCancel}
//         />

//         <AddCategory
//           showModal={this.state.showNewPopup}
//           handleModalShow={this.handleNewOnClick}
//           handleCloseModalShow={this.handleNewOnClick}
//           token={this.props.auth.token}
//           getCategories={this.getCategories}
//         />
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state) => {
//   return {
//     auth: state.auth.auth,
//   };
// };

// export default compose(withRouter, connect(mapStateToProps))(EditMerchant);
