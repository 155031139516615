import React from 'react';
import { bool, func, string } from 'prop-types';
import styles from './TagPill.module.scss';

const TagPill = ({ id, title, selected = false, onSelect }) => {
  const handleOnSelect = () => {
    onSelect(id);
  };
  const selectedStyle = selected ? styles.selected : '';

  return (
    <div
      className={[styles.pill, selectedStyle].join(' ')}
      onClick={handleOnSelect}
    >
      <span style={{ userSelect: 'none' }}>{title}</span>
    </div>
  );
};

TagPill.propTypes = {
  title: string.isRequired,
  selected: bool,
  onSelect: func.isRequired,
};

/* TagPill.propTypes = {
  title: propTypes.string,
  selected: propTypes.bool,
  onSelect: propTypes.func
}; */

export default TagPill;
