import React from 'react';
import moment from 'moment-timezone';

import styles from './EarningItem.module.scss';

const EarningItem = (props) => {
  const {
    earning,
    role,
    onClick,
    earning: {
      dateTime,
      earnedAmount,
      id,
      merchantName,
      subMerchantLocation,
      status,
    },
  } = props;

  const handleOnEarningSelect = () => {
    onClick && onClick(earning);
  };

  const rootStyle = onClick
    ? [styles.root__div, styles.clickable].join(' ')
    : styles.root__div;
  return (
    <tr className={rootStyle} onClick={handleOnEarningSelect}>
      <td>
        {moment(dateTime).tz('Asia/Kolkata').format('DD/MM/YYYY hh:mm A')}
      </td>
      <td>{id.toUpperCase()}</td>
      {role === 'ROLE_THYAGA_ADMINISTRATOR' ? <td>{merchantName}</td> : null}
      <td>{subMerchantLocation}</td>
      <td>{status}</td>
      <td>
        <p className={styles.earned_amount_text}>{earnedAmount.toFixed(2)}</p>
      </td>
    </tr>
  );
};

EarningItem.defaultProps = {
  onClick: null,
};

export default EarningItem;
