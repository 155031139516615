import React from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Checkbox, Toggle } from 'rsuite';
import useComponentState from '../../utils/useComponentState';

import styles from './MerchantFilterModal.module.scss';

const MerchantFilterModal = ({
  filterData,
  show,
  toggleModalVisibility,
  onFilter,
}) => {
  const [{ byStatus, status }, setState] = useComponentState({ ...filterData });

  const handleOnFilter = () => {
    onFilter({ byStatus, status });
  };

  const handleToggleModal = () => {
    setState({ byStatus: filterData.byStatus, status: filterData.status });
    toggleModalVisibility();
  };

  return (
    <Modal
      centered
      size='lg'
      show={show}
      onHide={handleToggleModal}
      backdrop='static'
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className={styles.section}>
          <Checkbox
            className={styles.checkbox}
            checked={byStatus}
            onChange={() => {
              setState({ byStatus: !byStatus });
            }}
          >
            Status
          </Checkbox>
          <Toggle
            size='lg'
            checkedChildren='Active'
            unCheckedChildren='Inactive'
            checked={status}
            disabled={!byStatus}
            onChange={() => {
              setState({ status: !status });
            }}
          />
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button type='button' onClick={handleOnFilter}>
          Show results
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MerchantFilterModal;
