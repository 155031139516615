import React from 'react';
import { connect } from 'react-redux';

import Dashboard from '../dashboard/Dashboard.screen';
import WelcomeScreen from '../../components/Welcome/WelcomeScreen';
import SubMerchantDashboard from '../dashboard/SubMerchantDashboard';

const Home = (props) => {
  const renderRoleBased = () => {
    switch (props.auth.details.role) {
      case 'ROLE_THYAGA_ACCOUNTANT':
      case 'ROLE_THYAGA_ADMINISTRATOR':
      case 'ROLE_THYAGA_CUSTOMER_CARE':
      case 'ROLE_THYAGA_MERCHANT_CONTROLLER':
        return <WelcomeScreen />;
      case 'ROLE_SUB_MERCHANT_ADMINISTRATOR':
        return <SubMerchantDashboard />;
      default:
        return <Dashboard />;
    }
  };

  return <div>{renderRoleBased()}</div>;
};

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(Home);
