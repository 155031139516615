import React from 'react';
import DatePicker from 'react-datepicker';

import styles from './SearchByDate.module.scss';

const SearchByDate = (props) => {
  const { onStartDateChange, onEndDateChange, startDate, endDate } = props;

  return (
    <div className={styles.date_picker__div}>
      <DatePicker
        className={styles.date_picker__input}
        selected={startDate}
        onChange={(date) => onStartDateChange(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        maxDate={endDate}
        placeholderText='Start date'
        isClearable
        dateFormat='dd/MM/yyyy'
      />
      <span className={styles.date_picke__p}>&#160;-&#160;</span>
      <DatePicker
        className={styles.date_picker__input}
        selected={endDate}
        onChange={(date) => onEndDateChange(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        placeholderText='End date'
        isClearable
        dateFormat='dd/MM/yyyy'
      />
    </div>
  );
};

export default SearchByDate;
