import { GET, POST, PUT } from './core';

export const getEndpointWithPrefix = (endpoint) => {
  return `/merchants/${endpoint}`;
};

export const searchMerchants = (term, token) => {
  const endpoint = getEndpointWithPrefix(`search/${term} `);

  return GET(endpoint, token);
};

export const getDashboard = (merchantId, token) => {
  const endpoint = getEndpointWithPrefix(`dashboard/${merchantId}`);

  return GET(endpoint, token);
};

export const addMerchant = (request, token) => {
  const endpoint = getEndpointWithPrefix('addNewMerchant');
  return POST(endpoint, request, token);
};

export const editMerchant = (request, token, id) => {
  const endpoint = getEndpointWithPrefix(`update/${id}`);
  return PUT(endpoint, request, token);
};

export const getMerchantCategories = (token) => {
  const endpoint = getEndpointWithPrefix('getMerchantCategories');
  return GET(endpoint, token);
};

export const getMerchantDetails = (token, id) => {
  const endpoint = getEndpointWithPrefix(id);
  return GET(endpoint, token);
};

export const getAllMerchant = (token) => {
  const endpoint = getEndpointWithPrefix('allmerchant');
  return GET(endpoint, token);
};

export const addMerchantCategory = (request, token) => {
  const endpoint = getEndpointWithPrefix('addNewMerchantCategory');
  return POST(endpoint, request, token);
};
