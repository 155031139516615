import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { PageHeader, Pagination } from '../../components';
import CheckableDataTable from '../../components/CheckableDataTable/CheckableDataTable';
import useComponentState from '../../utils/useComponentState';
import { getAllIPLogs, deleteIPLogs } from '../../api/IPLogs';
import Search from '../../components/Search/Search.component';
import RowPaginator from '../../components/RowPaginator/RowPaginator';
import styles from './IPLogs.module.scss';

const IPLogs = (props) => {
  const [state, setState] = useComponentState({
    tableHeading: {
      column: ['IP Address', 'Count', 'Blocked Time', 'Created Time'],
    },
    IPData: [],
    IPPages: 0,
    curPage: 0,
    IPBatchPages: 0,
    curBatchPage: 0,
    pageSize: 500,
    rowSize: 10,
    searchInput: '',
    tableData: [],
    isSelected: false,
    receiverName: '',
    searchId: '',
    ipSearchId: '',
    slicedIPData: [],
    IPLoadingError: '',
    loading: false,
    searchedMerchantMessage: '',
    isClearable: false,
    checkedRows: [],
    selectAll: false,
    filteredData: [],
    isApiCalled: false,
  });
  const {
    tableHeading,
    tableData,
    IPData,
    curPage,
    curBatchPage,
    IPBatchPages,
    searchInput,
    IPLoadingError,
    loading,
    IPPages,
    rowSize,
    slicedIPData,
    filteredData,
    searchedMerchantMessage,
    searchKey,
    searchId,
    isClearable,
    pageSize,
    isSelected,
    checkedRows,
    selectAll,
    ipSearchId,
    isApiCalled,
  } = state;

  useEffect(() => {
    getIPDetails(0);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filteredData) {
      setState({
        IPPages: getIPPages(filteredData),
        slicedIPData: filteredData.slice(0, rowSize),
      });
    }
    //eslint-disable-next-line
  }, [filteredData]);

  useEffect(() => {
    if (IPData) {
      const tableData = IPData.map(
        ({ ipAddress, count, blockedAt, createdAt }) => ({
          ipAddress,
          count,
          blockedAt,
          createdAt,
        })
      );
      setState({
        IPPages: getIPPages(IPData),
        tableData: tableData,
        slicedIPData: tableData.slice(0, rowSize),
        /* IPBatchPages: getIPBatchPages(IPData), */
      });
    }
    //eslint-disable-next-line
  }, [IPData]);

  useEffect(() => {
    setState({
      IPPages: getIPPages(IPData),
      slicedIPData: tableData.slice(0, rowSize),
    });
    //eslint-disable-next-line
  }, [rowSize]);

  const getIPDetails = async (page) => {
    setState({
      loading: true,
      /* IPData: [], */
      IPLoadingError: '',
      checkedRows: [],
    });
    try {
      const response = await getAllIPLogs(
        props.auth.auth.token,
        page,
        pageSize
      );
      setState({
        IPData: response.ipLogs,
        IPBatchPages: response.totalPage,
        curBatchPage: response.pageNo /* OTPBatchPages: response */,
        isApiCalled: false,
      });
    } catch (error) {
      setState({
        IPLoadingError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ loading: false });
  };

  const handleDeleteIPLogs = async () => {
    setState({
      loading: true,
    });
    try {
      const token = props.auth.auth.token;
      const response = await deleteIPLogs(checkedRows, token);
      getIPDetails(curBatchPage);
    } catch (error) {
      setState({
        fetchError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ loading: false });
  };

  const searchOnChange = (event) => {
    const inputValue = event.target.value;
    if (
      /^((?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.)?((?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.)?((?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.)?((?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)?)$/.test(
        inputValue
      )
    ) {
      setState({
        searchInput: event.target.value,
      });
    }
  };

  /* const searchIPById = async () => {
    setState({
      loading: true,
    });
    try {
      const request = {
        searchId: searchId,
        searchKey: searchKey,
        page: page,
      };
      const token = props.auth.auth.token;
      const response = await searchIPs(request, token);
      setState({ IPData: response });
    } catch (error) {
      setState({
        IPLoadingError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ loading: false });
  }; */

  const handleIPSearch = (ipAddress) => {
    if (ipAddress) {
      const filtered = IPData.filter((data) => {
        if (data.ipAddress === null) {
          return false;
        }
        return data.ipAddress.toLowerCase().includes(ipAddress.toLowerCase());
      });
      setState({
        filteredData: filtered /*
        slicedIPData: filtered.slice(0, 25),
        IPPages: getIPPages(filtered),
        searchedMerchantMessage: `id found for ${ipAddress}`*/,
      });
    }
  };

  const handleIPClear = () => {
    getIPDetails(curBatchPage);
    /* dataToRender: tableData, */
    /* slicedIPData: IPData.slice(0, 25),
      IPPages: getIPPages(IPData),
      searchedMerchantMessage: null, */
  };

  const handleRowPaginationChange = (event) => {
    setState({
      rowSize: event.target.value,
    });
  };

  const getIPPages = (data) => {
    let IPPages = 0;
    const mod = data.length % rowSize;
    if (mod > 0) {
      IPPages = data.length / rowSize - mod / rowSize + 1;
    } else {
      IPPages = data.length / rowSize;
    }
    return IPPages;
  };

  /*   const getIPBatchPages = (data) => {
    let IPBatchPages = 0;
    if (!data.hasOwnProperty('total')) {
      IPBatchPages = 1;
    } else {
      IPBatchPages = data.total;
    }
    return IPBatchPages;
  }; */

  const handlePagesPagination = (page) => {
    setState({ curPage: page });
    if (page > 0) {
      const sliceStart = page * rowSize;
      const sliceEnd = (page + 1) * rowSize;
      setState({
        slicedIPData: IPData.slice(sliceStart, sliceEnd),
      });
    } else {
      const sliceStart = 0;
      const sliceEnd = rowSize;
      setState({
        slicedIPData: IPData.slice(sliceStart, sliceEnd),
        checkedRows: [],
      });
    }
  };

  const handleBatchesPagination = (page) => {
    getIPDetails(page);
    setState({ curBatchPage: page });
  };
  /*   const handleOnClick = (id) => {
    const rowData = IPData.find((data) => data.id === id);
    setState({
      isSelected: !isSelected,
    });
  }; */

  const handleCheck = (rowId) => {
    if (checkedRows.includes(rowId)) {
      const updatedCheckedRows = checkedRows.filter((id) => id !== rowId);
      setState({ checkedRows: updatedCheckedRows });
    } else {
      setState({ checkedRows: [...checkedRows, rowId] });
    }
  };

  const handleSelectAll = (event) => {
    setState({ selectAll: event.target.checked });
    if (event.target.checked) {
      const selectedAll = slicedIPData.map((data) => data.ipAddress);
      setState({ checkedRows: [...new Set([...checkedRows, ...selectedAll])] });
    } else {
      const updatedCheckedRows = checkedRows.filter(
        (id) => !slicedIPData.some((data) => data.ipAddress === id)
      );
      setState({ checkedRows: updatedCheckedRows });
    }
  };

  return (
    <div className={styles.root_div}>
      <div>
        <PageHeader text='IP Logs' />
        <div className={styles.search_area_block}>
          <div className={styles.user_input}>
            <RowPaginator
              handleRowPaginationChange={handleRowPaginationChange}
              pageSize={rowSize}
            />
            <div className={styles.right}>
              <div className={styles.search}>
                {/* <Search
                  placeholder={'IP Address'}
                  onBtnPress={handleIPSearch}
                  onClearPress={handleIPClear}
                  searchOnChange={searchOnChange}
                  validation={'ipAddress'}
                  searchInput={searchInput}
                  max={50}
                /> */}
              </div>
              <div className={styles.button_container}>
                <Button
                  onClick={handleDeleteIPLogs}
                  className={styles.button}
                  variant='danger'
                  disabled={checkedRows.length === 0}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <CheckableDataTable
            /* totalData={IPData} */
            tableData={slicedIPData}
            tableheading={tableHeading}
            isSelected={isSelected}
            tag='ip-logs'
            fetchError={IPLoadingError}
            notFoundMessage={IPLoadingError}
            search_filter_loading={loading}
            searchFilterLoading={false}
            handleCheck={handleCheck}
            handleSelectAll={handleSelectAll}
            checkedRows={checkedRows}
            selectAll={selectAll}
          />
          {!IPLoadingError && (
            <div className={styles.dualPaginateContainer}>
              <div className={styles.batches}>
                <Pagination
                  pageCount={IPBatchPages}
                  onChange={handleBatchesPagination}
                  currentPage={0}
                />
                &nbsp;&nbsp;&nbsp;
                <span>
                  Batch {curBatchPage + 1} of {IPBatchPages}
                </span>
              </div>
              <div className={styles.pages}>
                <span>
                  Page {curPage + 1} of {IPPages}
                </span>
                &nbsp;&nbsp;&nbsp;
                <Pagination
                  pageCount={IPPages}
                  onChange={handlePagesPagination}
                  currentPage={0}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(IPLogs);
