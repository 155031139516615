import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, InputGroup } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Drawer } from 'rsuite';
import MerchantVoucherUploadModal from '../../components/Modals/MerchantVoucherUploadModal';
import { formatCurrency } from '../../utils/currencyFormatter';
import { Loading, PageHeader } from '../../components';
import DataTable from '../../components/DataTable/DataTable';
import useComponentState from '../../utils/useComponentState';
import { getDenominationsUploadSummary } from '../../api/merchantVoucher';

import styles from './UploadDenominations.module.scss';

const UploadDenominations = (props) => {
  const [state, setState] = useComponentState({
    tableHeading: {
      column: ['Batch Id', 'Merchant', 'Created at', 'Status', 'Uploaded by'],
    },
    tableData: [],
    loadingError: '',
    loading: false,
    filterLoading: false,
    showModal: false,
    selectedBatch: null,
  });
  const {
    tableHeading,
    tableData,
    loadingError,
    loading,
    filterLoading,
    showModal,
    selectedBatch,
    showDrawer,
  } = state;

  useEffect(() => {
    getUploadHistory();
    //eslint-disable-next-line
  }, []);

  const handleDrawerOnHide = () => {
    setState({ showDrawer: !showDrawer });
  };

  const renderDenominationDetails = (snapshot) => {
    return (
      <div className={`details_group ${styles.content}`}>
        <Card>
          <Card.Body>
            {JSON.parse(selectedBatch[snapshot]).map(
              ({ denomination, availableQuantity }) => {
                return (
                  <>
                    <div className='detail_group_content'>
                      <span className='content_header'>{`${formatCurrency(
                        denomination
                      )}`}</span>
                      <span className='content_value'>
                        {availableQuantity} Nos
                      </span>
                    </div>
                  </>
                );
              }
            )}
          </Card.Body>
        </Card>
      </div>
    );
  };

  const getUploadHistory = async () => {
    setState({ loading: true, tableData: [], loadingError: '' });
    try {
      const response = await getDenominationsUploadSummary(
        props.auth.auth.token
      );
      setState({ tableData: response });
    } catch (error) {
      setState({
        loadingError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ loading: false });
  };

  const renderPreviousSnapshot = () => {
    return (
      <div className='drawer_child'>
        <div className='header_group'>
          <span className='header_title'>Pre-Upload Snapshot</span>
        </div>
        {renderDenominationDetails('previousSnapshot')}
      </div>
    );
  };

  const renderCurrentSnapshot = () => {
    return (
      <div className='drawer_child'>
        <div className='header_group'>
          <span className='header_title'>Post-Upload Snapshot</span>
        </div>
        {renderDenominationDetails('currentSnapshot')}
      </div>
    );
  };

  const renderDrawer = () => {
    return (
      <div className='purchase_drawer_root'>
        {renderPreviousSnapshot()}
        {renderCurrentSnapshot()};
      </div>
    );
  };

  const handleOnClick = (id) => {
    const selectedBatch = tableData.find((data) => data.batchId === id);
    setState({ selectedBatch, showDrawer: true });
  };

  const handleShowUploadModal = () => {
    setState({ showModal: !showModal });
  };

  return (
    <div className={styles.root_div}>
      {loading ? (
        <Loading text='Loading' />
      ) : (
        <>
          <div>
            <PageHeader text='Upload Denominations' />
            {loadingError ? (
              <p>{loadingError}</p>
            ) : (
              <div>
                <div>
                  <InputGroup className='mb-3 mt-3'>
                    <Button
                      variant='secondary'
                      onClick={() => handleShowUploadModal()}
                    >
                      Upload
                    </Button>
                  </InputGroup>
                </div>

                <DataTable
                  tableData={tableData}
                  tableheading={tableHeading}
                  handleOnClick={handleOnClick}
                  tag='denominationUploads'
                  fetchError=''
                  searchFilterLoading={false}
                />
              </div>
            )}
          </div>
          <Drawer show={showDrawer} onHide={handleDrawerOnHide}>
            {selectedBatch && renderDrawer()}
          </Drawer>
          <MerchantVoucherUploadModal
            token={props.auth.auth.token}
            show={showModal}
            handleClose={handleShowUploadModal}
          />
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(UploadDenominations);
