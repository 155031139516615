import AuthorizedComponent from '../components/hoc/AuthorizedComponent';
import {
  AddOffer,
  AddTerminal,
  ContactUs,
  Dashboard,
  ViewEarnings,
  ViewOffers,
  ViewPayments,
  ViewTerminals,
  AddPayment,
  AddMerchant,
  EditMerchant,
  Home,
  ViewMerchants,
  ViewPurchases,
  PendingPurchases,
  ViewVouchers,
  OTPLogs,
  IPLogs,
  DenominationsSummary,
  UploadDenominations,
} from '../screens';
import UserDetails from '../screens/userDetails/userDetails.screen';
import { EarningsRoles } from '../types/RedemptionEnums';

export const acceptOfferRole = ['ROLE_THYAGA_ADMINISTRATOR'];
const acceptTerminalsRole = [
  'ROLE_THYAGA_ADMINISTRATOR',
  'ROLE_THYAGA_MERCHANT_CONTROLLER',
  'ROLE_THYAGA_CUSTOMER_CARE',
];
const acceptEarningsRole = Object.values(EarningsRoles);

const acceptAddMerchantRole = [
  'ROLE_THYAGA_ADMINISTRATOR',
  'ROLE_THYAGA_CUSTOMER_CARE',
  'ROLE_THYAGA_MERCHANT_CONTROLLER',
];

const acceptPaymentsRole = ['ROLE_MERCHANT_ADMINISTRATOR'];
const acceptAddPaymentRole = [
  'ROLE_THYAGA_ADMINISTRATOR',
  'ROLE_THYAGA_ACCOUNTANT',
];

const acceptViewPurchases = [
  'ROLE_THYAGA_ADMINISTRATOR',
  'ROLE_THYAGA_CUSTOMER_CARE',
  'ROLE_THYAGA_ACCOUNTANT',
];
const acceptUserDetails = ['ROLE_THYAGA_ADMINISTRATOR'];
const acceptViewVouchers = [
  'ROLE_THYAGA_ADMINISTRATOR',
  'ROLE_THYAGA_CUSTOMER_CARE',
  'ROLE_THYAGA_ACCOUNTANT',
];
const acceptUserLogs = [
  'ROLE_THYAGA_ADMINISTRATOR',
  'ROLE_THYAGA_CUSTOMER_CARE',
];
const acceptMerchantVouchers = [
  'ROLE_THYAGA_ADMINISTRATOR',
  'ROLE_THYAGA_MERCHANT_CONTROLLER',
];

const routes = [
  {
    name: 'Home',
    path: '/',
    component: Home,
  },
  {
    name: 'User Details',
    path: '/user-details',
    acceptUserRole: acceptUserDetails,
    component: AuthorizedComponent(UserDetails, acceptUserDetails),
  },
  {
    name: 'Security Logs',
    path: '/security',
    acceptUserRole: acceptUserLogs,
    children: [
      {
        name: 'IP Logs',
        path: '/ip-logs',
        component: AuthorizedComponent(IPLogs, acceptUserLogs),
      },
      {
        name: 'OTP Logs',
        path: '/otp-logs',
        component: AuthorizedComponent(OTPLogs, acceptUserLogs),
      },
    ],
  },
  {
    name: 'Offers',
    path: '/offers',
    acceptUserRole: acceptOfferRole,
    children: [
      {
        name: 'View Offers',
        path: '/',
        component: AuthorizedComponent(ViewOffers, acceptOfferRole),
      },
      {
        name: 'Add Offer',
        path: '/add-offer',
        component: AuthorizedComponent(AddOffer, acceptOfferRole),
      },
    ],
  },
  {
    name: 'Terminals',
    path: '/terminals',
    acceptUserRole: acceptTerminalsRole,
    children: [
      {
        name: 'View Terminals',
        path: '',
        component: AuthorizedComponent(ViewTerminals, acceptTerminalsRole),
      },
      {
        name: 'Add Terminal',
        path: '/add-terminal',
        component: AuthorizedComponent(AddTerminal, acceptTerminalsRole),
      },
    ],
  },
  {
    name: 'Earnings',
    path: '/earnings',
    acceptUserRole: acceptEarningsRole,
    children: [
      {
        name: 'View Redemptions',
        path: '',
        component: AuthorizedComponent(ViewEarnings, acceptEarningsRole),
      },
    ],
  },
  {
    name: 'Payments',
    path: '/payments',
    acceptUserRole: acceptPaymentsRole,
    children: [
      {
        name: 'View Payments',
        path: '',
        component: AuthorizedComponent(ViewPayments, acceptPaymentsRole),
      },
    ],
  },
  {
    name: 'Payments',
    path: '/payments',
    acceptUserRole: acceptAddPaymentRole,
    children: [
      {
        name: 'View Payments',
        path: '/payments',
        component: AuthorizedComponent(ViewPayments, acceptAddPaymentRole),
      },
      {
        name: 'Add Payment',
        path: '/add-payment',
        component: AuthorizedComponent(AddPayment, acceptAddPaymentRole),
      },
    ],
  },
  {
    name: 'Merchants',
    path: '/merchants',
    acceptUserRole: acceptAddMerchantRole,
    children: [
      {
        name: 'View Merchants',
        path: '/view-Merchants',
        component: AuthorizedComponent(ViewMerchants, acceptAddMerchantRole),
      },
      {
        name: 'Add Merchant',
        path: '/add-Merchant',
        component: AuthorizedComponent(AddMerchant, acceptAddMerchantRole),
      },
      {
        name: 'Edit Merchant',
        path: '/edit-Merchant/:id',
        hidden: true,
        component: AuthorizedComponent(EditMerchant, acceptAddMerchantRole),
      },
    ],
  },
  {
    name: 'Merchant Vouchers',
    path: '/merchant-vouchers',
    acceptUserRole: acceptMerchantVouchers,
    children: [
      {
        name: 'Denominations Summary',
        path: '/denominations-summary',
        component: AuthorizedComponent(
          DenominationsSummary,
          acceptMerchantVouchers
        ),
      },
      {
        name: 'Upload Denominations',
        path: '/upload-denominations',
        component: AuthorizedComponent(
          UploadDenominations,
          acceptMerchantVouchers
        ),
      },
    ],
  },
  {
    name: 'Purchases',
    path: '/purchases',
    acceptUserRole: acceptViewPurchases,
    children: [
      {
        name: 'View Purchases',
        path: '/view-Purchases',
        component: AuthorizedComponent(ViewPurchases, acceptViewPurchases),
      },
      {
        name: 'Pending Purchases',
        path: '/pending-Purchases',
        component: AuthorizedComponent(PendingPurchases, acceptViewPurchases),
      },
    ],
  },
  {
    name: 'Vouchers',
    path: '/vouchers',
    acceptUserRole: acceptViewVouchers,
    children: [
      {
        name: 'View Vouchers',
        path: '/view-vouchers',
        component: AuthorizedComponent(ViewVouchers, acceptViewVouchers),
      },
    ],
  },

  {
    name: 'Contact Us',
    path: '/contact-us',
    component: ContactUs,
  },
];

export default routes;
