import { GET, DELETE } from './core';

export const getEndpointWithPrefix = (endpoint) => {
  return `/otp/${endpoint}`;
};

export const getAllIPLogs = (token, page, pageSize) => {
  const endpoint = getEndpointWithPrefix(
    `getAllOTPRequestIp?page=${page}&pageSize=${pageSize}`
  );
  return GET(endpoint, token);
};

export const deleteIPLogs = (request, token) => {
  const endpoint = getEndpointWithPrefix('deleteOTPRequestIP');
  return DELETE(endpoint, request, token);
};
