import { GET, DELETE } from './core';

export const getEndpointWithPrefix = (endpoint) => {
  return `/otp/${endpoint}`;
};

export const getAllOTPLogs = (token, page, pageSize) => {
  const endpoint = getEndpointWithPrefix(
    `getAllOTPLog?page=${page}&pageSize=${pageSize}`
  );
  return GET(endpoint, token);
};

export const searchOTPLogs = (token, recipient, page, pageSize) => {
  const endpoint = getEndpointWithPrefix(
    `getsearchContactNoOTPLog?recipient=${recipient}&page=${page}&pageSize=${pageSize}`
  );
  return GET(endpoint, token);
};

export const deleteOTPLogs = (request, token) => {
  const endpoint = getEndpointWithPrefix('deleteOTPLog');
  return DELETE(endpoint, request, token);
};
