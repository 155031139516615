import React from 'react';

import styles from './DashboardInfoCard.module.scss';

const FormattedValue = ({ value }) => {
  const values = value?.toString().split('.');

  return (
    <span>
      {values[0]}
      {values[1] && <span className={styles.decimal}>.{values[1]}</span>}
    </span>
  );
};

export const DashboardInfoCard = ({ label, value }) => {
  return (
    <div className={styles.card}>
      <FormattedValue value={value} />
      <label>{label}</label>
    </div>
  );
};

export const DashboardInfoCardList = ({ children }) => {
  return <div className={styles.list}>{children}</div>;
};
