import { GET, POST } from './core';

export const getEndpointWithPrefix = (endpoint) => {
  return `/${endpoint}`;
};

export const getMerchantTags = (token) => {
  const endpoint = getEndpointWithPrefix('tags/getAll');
  return GET(endpoint, token);
};

export const getMerchantCodes = (request, token) => {
  const endpoint = getEndpointWithPrefix('redemption/code');
  return POST(endpoint, request, token).then((response) => {
    return response;
  });
};
