import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DashboardSummary from './DashboardSummary';
import { getDashboard } from '../../api/subMerchant';
import { sortBydate } from '../../components/hoc/SortField';
import useComponentState from '../../utils/useComponentState';
import { storeDashboardDetails } from '../../actions/dashboard';
import { Loading, PageHeader, EarningItem } from '../../components';

import './SubMerchantDashboard.scss';

const SubMerchantDashboard = (props) => {
  const [state, setState] = useComponentState({
    dashboard: null,
    dashboardLoading: true,
    dashboardError: null,
  });

  const {
    auth: { token },
  } = props;
  const { dashboard, dashboardLoading, dashboardError } = state;
  const currentUser = props.user.currentUser;

  useEffect(() => {
    handleGetDashboardFromAPI();
    //eslint-disable-next-line
  }, []);

  const handleGetDashboardFromAPI = async () => {
    try {
      setState({ dashboardLoading: true, dashboardError: null });
      const response = await getDashboard(currentUser.id, token);
      setState({ dashboardLoading: false, dashboard: { ...response } });
      props.storeDashboardDetails(response);
    } catch (err) {
      setState({ dashboardLoading: false, dashboardError: err.message });
    }
  };

  const handleOnEarningsSeeMoreClick = () => {
    props.history.push('/earnings');
  };

  const renderLoading = () => {
    return <Loading text='Loading Dashboard' />;
  };

  const renderError = () => {
    return <div className='dashboard_error'>{dashboardError}</div>;
  };

  const renderTableData = () => {
    const {
      earnings: { earnings },
    } = dashboard;
    sortBydate(earnings);
    return (
      <div className='submerchant_earnings__div'>
        <PageHeader text='Earnings' />
        <div className='table__header'>
          <span>Date (IST)</span>
          <span>Redemption Id</span>
          <span>Reference Code</span>
          <span>Status</span>
          <span>Earned Amount (LKR)</span>
        </div>
        {earnings.length === 0 ? (
          <div>
            <p className='redemption_error'>No redemptions</p>
          </div>
        ) : (
          earnings.map((earning) => {
            return <EarningItem earning={earning} />;
          })
        )}
        {earnings.length === 10 && (
          <div className='table__footer'>
            <span onClick={handleOnEarningsSeeMoreClick}>See More</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='submerchant_dashboard_root_div'>
      {dashboardLoading ? (
        renderLoading()
      ) : dashboardError ? (
        renderError()
      ) : (
        <div className='submerchant_dashboard_div'>
          <DashboardSummary
            totalRedemptionAmount={dashboard.totalRedemptionAmount}
            currentMonthRedemptionAmount={
              dashboard.currentMonthRedemptionAmount
            }
            averageRedemptionAmount={dashboard.averageRedemptionAmount}
            numberOfVouchers={dashboard.numberOfVouchers}
          />
          <div className='submerchant_tables__div'>{renderTableData()}</div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    dashboard: state.dashboard,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeDashboardDetails: (data) => {
      dispatch(storeDashboardDetails(data));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubMerchantDashboard)
);
