import React from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Spinner from 'react-bootstrap/Spinner';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RedemptionRenotifyModal = (props) => {
  const {
    show,
    handleClose,
    handleAddField,
    handleRemoveField,
    recipient,
    overrideNumber,
    handleOverrideNumber,
    mobileNumberFields,
    handleMobileChange,
    validated,
    handleRenotifyRedemption,
    resendSuccess,
    selectedEarning,
    resendLoading,
  } = props;

  return (
    <Modal
      show={show}
      style={{ opacity: 1 }}
      animation={false}
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Re-notify {recipient.charAt(0).toUpperCase() + recipient.slice(1)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row style={{ paddingBottom: '1rem' }}>
          {resendSuccess ? (
            <Col sm>
              Redemption <strong>{selectedEarning.id}</strong> has been
              successfully re-notified to the {recipient}
            </Col>
          ) : (
            <Col sm>
              Clicking on <strong>Renotify</strong> will re-notify to the{' '}
              {recipient} of redemption {selectedEarning.id}
            </Col>
          )}
        </Row>

        {!resendSuccess && (
          <>
            <Row>
              <Col sm>
                <Form.Group>
                  <Form.Check
                    reverse
                    checked={overrideNumber}
                    onChange={handleOverrideNumber}
                    type='checkbox'
                    name='checked'
                    readOnly
                    label={`Override default ${recipient} mobile number (Optional)`}
                  />
                </Form.Group>
              </Col>
            </Row>

            {overrideNumber &&
              mobileNumberFields.map((field) => (
                <Row>
                  <Col sm>
                    <Form.Group>
                      <PhoneInput
                        required
                        maxLength={15}
                        defaultCountry={'LK'}
                        value={field.value}
                        placeholder='Mobile Number'
                        onChange={(mobileNo) =>
                          handleMobileChange(mobileNo, field.id)
                        }
                        className={
                          validated
                            ? field.error
                              ? 'border border-danger'
                              : 'border border-success'
                            : 'border'
                        }
                      />

                      {field.error && validated && (
                        <small className='text-danger'>{field.error}</small>
                      )}
                    </Form.Group>
                  </Col>
                  <div style={{ paddingRight: '15px' }}>
                    {field.id > 1 ? (
                      <Button
                        variant='danger'
                        onClick={() => handleRemoveField(field.id)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </Button>
                    ) : (
                      <Button variant='success' onClick={handleAddField}>
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    )}
                  </div>
                </Row>
              ))}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          {resendSuccess ? 'Close' : 'Cancel'}
        </Button>
        {!resendSuccess && (
          <Button
            variant='primary'
            onClick={() => handleRenotifyRedemption()}
            disabled={resendLoading}
            style={{ width: '90px' }}
          >
            {resendLoading ? (
              <Spinner size='sm' animation='border' />
            ) : (
              'Renotify'
            )}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default RedemptionRenotifyModal;
