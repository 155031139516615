import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Drawer } from 'rsuite';
import moment from 'moment-timezone';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import RedemptionRenotifyModal from '../../components/Modals/RedemptionRenotifyModal';
import {
  Alert,
  Button,
  OverlayTrigger,
  Popover,
  Tooltip,
} from 'react-bootstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import useComponentState from '../../utils/useComponentState';
import {
  Loading,
  PageHeader,
  Pagination,
  EarningItem,
  AppButton,
} from '../../components';
import {
  getEarnings,
  unredeem,
  getEarningsByDate,
  getEarningsByRidRcode,
  getAllRedemptionInExcelSheet,
  getAllRedemptionByDateInExcelSheet,
  unredeemState,
  renotifyRedemption,
} from '../../api/earning';
import { login } from '../../api/auth';
import { sortBydate } from './../../components/hoc/SortField';
import SearchByDate from '../../components/SearchByDate/SearchByDate.components';
import SearchByRedemtionIdOrReferanceCode from '../../components/SearchByRedemtionIdOrReferanceCode/SearchByRedemtionIdOrReferanceCode.component';
import SearchBySubMerchant from '../../components/SearchBySubMerchant/SearchBySubMerchant';
import SearchRedemptionDrodown from '../../components/SearchRedemptionDrodown/SearchRedemptionDrodown.component';
import UnredeemPopup from '../../components/unredeemPopup/UnredeemPopup.component';
import { faCopy, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import refreshIcon from '../../styles/images/refresh.png';

import styles from './ViewEarnings.module.scss';
import './PhoneInput.scss';
import { EarningsRoles } from '../../types/RedemptionEnums';
import { copyToClipboard } from '../../utils/helper';

const ViewEarnings = (props) => {
  const [state, setState] = useComponentState({
    earningsLoading: false,
    authLoading: false,
    earnings: {
      total: 0,
      current: 0,
      earnings: [],
    },
    earningsError: null,
    selectedEarning: null,
    unredemptionLoading: false,
    unredemptionError: null,
    startDate: null,
    endDate: null,
    searchRidIcode: '',
    selectIdType: 'REDEMPTION_ID',
    selectSubMearchant: 0,
    showUnredeemPopup: false,
    password: '',
    passwordError: '',
    downloadStatus: '',
    downloadErrorMessage: '',
    subMerchants: [],
    mobileNumberFields: [],
    validated: false,
    notifyTo: '',
    recipient: '',
    showRenotifyModal: false,
    overrideNumber: false,
    resendSuccess: false,
    resendLoading: false,
    hasError: false,
  });

  const {
    auth: {
      auth: {
        token,
        details: { role },
      },
    },
  } = props;

  const merchantId = props.user.currentUser?.id;

  const {
    earningsLoading,
    authLoading,
    earnings: { total, current, earnings },
    earningsError,
    selectedEarning,
    unredemptionLoading,
    unredemptionError,
    startDate,
    endDate,
    searchRidIcode,
    selectSubMearchant,
    selectIdType,
    showUnredeemPopup,
    password,
    passwordError,
    downloadStatus,
    downloadErrorMessage,
    subMerchants,
    mobileNumberFields,
    validated,
    notifyTo,
    recipient,
    showRenotifyModal,
    overrideNumber,
    resendSuccess,
    resendLoading,
    hasError,
  } = state;

  const [copied, setCopied] = useState(false);

  const { users, currentUser } = props.user;

  useEffect(() => {
    handleGetEarningsFromApi(0);
    getSubMerchants();
    //eslint-disable-next-line
  }, [props.user.currentUser]);

  const refreshEarnings = () => {
    handleGetEarningsFromApi(0);
    getSubMerchants();
  };

  const getSubMerchants = () => {
    if (!checkForSubMerchantAdmin()) {
      const user = users.filter((item) => item.merchant.id === currentUser.id);
      setState({ subMerchants: user[0]?.subMerchants });
    }
  };

  const checkForSubMerchantAdmin = () => {
    return role === 'ROLE_SUB_MERCHANT_ADMINISTRATOR';
  };

  const handleErrorCodes = (error) => {
    switch (error.message) {
      case 'User is not a merchant.':
        handleSetError('User is not a merchant');
        break;
      case 'Not Found':
        handleSetError('Entity not found');
        break;
      default:
        handleSetError(error.message);
    }
  };

  const handleSetError = (err) => {
    setState({ earningsLoading: false, earningsError: err.message });
  };

  const handleOnPagechange = (value) => {
    if (startDate && endDate) {
      handleGetEarningsSearchByDateFromApi(startDate, endDate, value);
    } else if (searchRidIcode.trim() !== '') {
      handleGetEarningsSearchByRidRcodeFromApi(searchRidIcode, value);
    } else {
      handleGetEarningsFromApi(value);
    }
  };

  const handleOnSubmitSearchRidRcode = (data) => {
    data.preventDefault();
    setState({ startDate: null, endDate: null });
    if (searchRidIcode.trim() !== '') {
      handleGetEarningsSearchByRidRcodeFromApi(searchRidIcode, 0);
    } else {
      setState({ searchRidIcode: '' });
      handleGetEarningsFromApi(0);
    }
  };

  const handleOnChangeSearchRidRcode = (data) => {
    setState({ searchRidIcode: data.target.value });
  };

  const handleOnCleanSearchRidRcode = () => {
    setState({ searchRidIcode: '' });
    if (state.searchRidIcode !== '') {
      handleGetEarningsFromApi(0);
    }
  };

  const handleOnSelectIdType = (data) => {
    setState({
      selectIdType: data,
      startDate: null,
      endDate: null,
      searchRidIcode: '',
    });
    handleGetEarningsFromApi(0);
  };

  const handleOnStartDatChange = (date) => {
    setState({ startDate: date });
    if (date && endDate) {
      setState({ searchRidIcode: '' });
      handleGetEarningsSearchByDateFromApi(date, endDate, 0);
    }
    if (!date && !endDate) {
      handleGetEarningsFromApi(0);
    }
  };
  const handleOnEndDatChange = (date) => {
    setState({ endDate: date });
    if (date && startDate) {
      setState({ searchRidIcode: '' });
      handleGetEarningsSearchByDateFromApi(startDate, date, 0);
    }
    if (!date && !startDate) {
      handleGetEarningsFromApi(0);
    }
  };

  const handleGetEarningsSearchByRidRcodeFromApi = async (
    redemptionid,
    page,
    subMerchant = selectSubMearchant
  ) => {
    try {
      setState({ earningsLoading: true, earningsError: null });
      const subMerchantId = checkForSubMerchantAdmin()
        ? currentUser.id
        : subMerchant;
      const request = {
        merchantId,
        subMerchantId,
        searchKey: redemptionid,
        selectIdType,
        page,
      };
      const response = await getEarningsByRidRcode(request, token);

      sortBydate(response.earnings);
      setState({ earningsLoading: false, earnings: { ...response } });
    } catch (err) {
      handleErrorCodes(err);
    }
  };

  const handleAddField = () => {
    const newObj = {
      id: !mobileNumberFields.length
        ? 1
        : mobileNumberFields[mobileNumberFields.length - 1].id + 1,
      value: '',
      error: null,
    };
    setState({
      mobileNumberFields: [...mobileNumberFields, newObj],
      validated: false,
    });
  };

  const handleRemoveField = (id) => {
    const newArray = mobileNumberFields.filter((item) => item.id !== id);
    setState({ mobileNumberFields: newArray });
  };

  const handleGetEarningsSearchByDateFromApi = async (
    startDate,
    endDate,
    page,
    subMerchant = selectSubMearchant
  ) => {
    try {
      setState({ earningsLoading: true, earningsError: null });
      const subMerchantId = checkForSubMerchantAdmin()
        ? currentUser.id
        : subMerchant;
      const request = {
        page: page,
        size: 10,
        startDate: moment(startDate).format(),
        endDate: moment(endDate).format(),
        subMerchantId,
        merchantId,
      };
      const response = await getEarningsByDate(request, token);

      sortBydate(response.earnings);
      setState({ earningsLoading: false, earnings: { ...response } });
    } catch (err) {
      handleErrorCodes(err);
    }
  };

  const handleGetEarningsFromApi = async (
    value,
    subMerchant = selectSubMearchant
  ) => {
    try {
      setState({ earningsLoading: true, earningsError: null });
      const subMerchantId = checkForSubMerchantAdmin()
        ? currentUser.id
        : subMerchant;
      const request = {
        merchantId,
        subMerchantId,
        page: value,
      };
      const response = await getEarnings(request, token);
      sortBydate(response.earnings);
      setState({
        earningsLoading: false,
        earnings: { ...response },
        initialLoading: false,
      });
    } catch (err) {
      handleErrorCodes(err);
    }
  };

  const handleSubMearchant = (subMearchant) => {
    setState({
      selectSubMearchant: subMearchant,
      startDate: null,
      endDate: null,
      searchRidIcode: '',
    });
    handleGetEarningsFromApi(0, subMearchant);
  };

  const renderLoading = () => {
    return <Loading text='Loading' />;
  };

  const renderError = (err) => {
    return <div className={styles.error__div}>{err}</div>;
  };

  const handleOnEarningDeselect = () => {
    if (!unredemptionLoading) {
      setState({ selectedEarning: null, unredemptionError: null });
    }
  };

  const handleOnEarningSelect = (earning) => {
    setState({ selectedEarning: { ...earning } });
  };

  const handleOnUnredeemClick = async () => {
    try {
      setState({
        unredemptionLoading: true,
      });
      const { id } = selectedEarning;
      await unredeemState(id, token);
      setState({
        unredemptionLoading: false,
        showUnredeemPopup: !showUnredeemPopup,
        password: '',
      });
    } catch (error) {
      setState({
        unredemptionLoading: false,
        password: '',
      });
      switch (error.message) {
        case 'UNREDEEM_PERIOD_END':
          setState({
            unredemptionError: 'Unredeem time period is over',
          });
          break;
        default:
          setState({
            unredemptionError: error.message,
          });
      }
    }
  };

  const handleUnredeem = async () => {
    try {
      const { id } = selectedEarning;
      setState({
        unredemptionLoading: true,
        unredemptionError: null,
      });

      const request = {
        redemptionId: id,
        merchantId,
        role,
        page: current,
      };

      const response = await unredeem(request, token);
      const updatedSelectedRedemption = response.earnings.find(
        (red) => red.id === id
      );
      sortBydate(response.earnings);
      setState({
        selectedEarning: { ...updatedSelectedRedemption },
        earnings: { ...response },
        unredemptionLoading: false,
      });
    } catch (err) {
      switch (err.message) {
        case 'UNAUTHORIZED_USER':
          setState({
            unredemptionLoading: false,
            unredemptionError: 'Unauthorized Error. Please try again',
          });
          break;
        case 'MERCHANT_MISMATCH':
          setState({
            unredemptionLoading: false,
            unredemptionError: 'Invalid request. Please try again',
          });
          break;
        case 'REDEMPTION_ALREADY_UNREDEEMED':
          setState({
            unredemptionLoading: false,
            unredemptionError: 'Redemption already Unredeemed',
          });
          break;
        case 'CORE-MERCHANT_NOT_FOUND':
          setState({
            unredemptionLoading: false,
            unredemptionError: 'Invalid request. Please try again',
          });
          break;
        case 'MERCHANT_FINANCE_DETAILS_NOT_FOUND':
          setState({
            unredemptionLoading: false,
            unredemptionError: 'Invalid request. Please try again',
          });
          break;
        case 'UNREDEEM_PERIOD_END':
          setState({
            unredemptionLoading: false,
            unredemptionError: 'Unredeem time period is over',
          });
          break;
        default:
          setState({
            unredemptionLoading: false,
            unredemptionError: err.message,
          });
      }
    }
  };

  const handleOverrideNumber = () => {
    if (!overrideNumber) {
      handleAddField();
    } else {
      setState({ mobileNumberFields: [] });
    }
    setState({ overrideNumber: !overrideNumber, validated: false });
  };

  const handleOnNotifyCustomer = () => {
    setState({
      showRenotifyModal: true,
      notifyTo: 'NOTIFY_TO_USER',
      recipient: 'customer',
    });
  };

  const handleOnNotifyMerchant = () => {
    setState({
      showRenotifyModal: true,
      notifyTo: 'NOTIFY_TO_MERCHANT',
      recipient: 'merchant',
    });
  };

  const handleClose = () => {
    setState({
      showRenotifyModal: false,
      validated: false,
      resendSuccess: false,
      overrideNumber: false,
      mobileNumberFields: [],
    });
  };

  const handleRenotifyRedemption = async () => {
    let mobileNumberArray = [];
    const { isValid } = isAllPhoneFieldsValid();
    if (!isValid) {
      mobileNumberFields.map((item) => {
        validatePhoneNumber(item.value, item.id);
      });
      setState({ validated: true });
      return false;
    } else {
      overrideNumber &&
        mobileNumberFields.map((item) => {
          mobileNumberArray.push(item.value);
        });
    }
    try {
      const { id } = selectedEarning;
      setState({ resendLoading: true });

      const request = {
        redemptionId: id,
        mobileNumbers: mobileNumberArray,
        notifyTo,
      };

      await renotifyRedemption(request, token);
      setState({ resendSuccess: true, resendLoading: false });
    } catch (err) {
      setState({ resendLoading: false });
    }
  };

  const isAllPhoneFieldsValid = () => {
    let isValid = true;
    overrideNumber &&
      mobileNumberFields.map((item) => {
        if (!item.value || (item.value && !isPossiblePhoneNumber(item.value))) {
          isValid = false;
        }
      });
    return { isValid };
  };

  const validatePhoneNumber = (receiverMobile, id) => {
    setState((prevState) => {
      const updatedFields = prevState.mobileNumberFields.map((item) => {
        if (item.id === id) {
          let mobileError = '';
          if (receiverMobile) {
            if (!isPossiblePhoneNumber(receiverMobile)) {
              mobileError = 'Enter a valid mobile number';
            }
          } else {
            mobileError = "Mobile number can't be empty";
          }
          return { ...item, error: mobileError };
        }
        // Return the original item if the id doesn't match
        return item;
      });
      return { mobileNumberFields: updatedFields };
    });
  };

  const handleMobileChange = (receiverMobile, id) => {
    const updatedValue = mobileNumberFields.map((item) => {
      if (item.id === id) {
        return { ...item, value: receiverMobile }; // Update matched mobile
      }
      return item;
    });

    setState({ mobileNumberFields: updatedValue });
    if (validated) {
      validatePhoneNumber(receiverMobile, id);
    }
  };

  const handleOnPasswordChange = (data) => {
    setState({
      password: data.target.value,
    });
  };

  const handleOnPasswordSubmit = async () => {
    setState({
      authLoading: true,
      passwordError: '',
    });
    const authDetails = {
      email: props.auth.auth.details.email,
      password: password,
    };
    try {
      await login(authDetails);
      setState({
        showUnredeemPopup: false,
        authLoading: false,
      });
      handleUnredeem();
    } catch (error) {
      setState({
        passwordError: 'Incorrect password, please try again',
        authLoading: false,
      });
    }
  };

  const handleOnUnredeemCansel = () => {
    setState({
      showUnredeemPopup: false,
      password: '',
      passwordError: '',
    });
  };

  const copyRedemptionId = (text) => {
    copyToClipboard(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  const renderSelectedEarning = () => {
    const {
      id,
      dateTime,
      referenceCode,
      voucherId,
      redeemedAmount,
      earnedAmount,
      customerName,
      mobileNo,
      terminalId,
      subMerchantId,
      status,
      subMerchantLocation,
      unRedeemedDateTime,
      platformEarnedAmount,
      merchantId: mid,
      merchantName,
    } = selectedEarning;

    return unredemptionLoading ? (
      renderLoading()
    ) : (
      <div className={styles.drawer__div}>
        <div>
          <h5 className={styles.redemption__header}>
            Redemption ID
            <OverlayTrigger
              placement={'top'}
              overlay={
                <Tooltip>
                  {copied
                    ? `Copied ${id.toUpperCase()} ✅`
                    : `Click to copy ${id.toUpperCase()}`}
                </Tooltip>
              }
            >
              <Button
                onClick={() => copyRedemptionId(id.toUpperCase())}
                variant='outline-dark'
                size='sm'
                className={styles.copy_icon}
              >
                <FontAwesomeIcon icon={faCopy} />
              </Button>
            </OverlayTrigger>
          </h5>

          <h5 className={styles.redemption__header}>{id.toUpperCase()}</h5>
        </div>
        <div className={styles.drawer__child}>
          <div className={styles.details__horizontal}>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>Date/Time</span>
              <span className={styles.detail__value}>
                {moment(dateTime)
                  .tz('Asia/Kolkata')
                  .format('DD/MM/YYYY hh:mm A')}{' '}
                (IST)
              </span>
            </div>
            {unRedeemedDateTime && (
              <div className={styles.detail__group__nested}>
                <span className={styles.detail__header}>Un-Redeemed Time</span>
                <span className={styles.detail__value}>
                  {moment(unRedeemedDateTime)
                    .tz('Asia/Kolkata')
                    .format('DD/MM/YYYY hh:mm A')}{' '}
                  (IST)
                </span>
              </div>
            )}
          </div>
          <div className={styles.details__horizontal}>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>Voucher ID</span>
              <span className={styles.detail__value}>{voucherId}</span>
            </div>
          </div>
          <div className={styles.details__horizontal}>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>
                Sub Merchant Location ( {subMerchantId} )
              </span>
              <span className={styles.detail__value}>
                {subMerchantLocation}
              </span>
            </div>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>Terminal ID</span>
              <span className={styles.detail__value}>{terminalId}</span>
            </div>
          </div>
          <div className={styles.details__horizontal}>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>Reference Code</span>
              <span className={styles.detail__value}>{referenceCode}</span>
            </div>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>Status</span>
              <span className={styles.detail__value}>{status}</span>
            </div>
          </div>
          <div className={styles.details__horizontal}>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>Customer Name</span>
              <span className={styles.detail__value}>{customerName}</span>
            </div>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>Mobile No</span>
              <span className={styles.detail__value}>{mobileNo}</span>
            </div>
          </div>
          <div className={styles.details__horizontal}>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>
                Redeemed Amount (LKR)
              </span>
              <span className={styles.detail__value}>
                {redeemedAmount.toFixed(2)}
              </span>
            </div>
            {role !== EarningsRoles.MerchantRedemptions && (
              <div className={styles.detail__group__nested}>
                <span className={styles.detail__header}>
                  Earned Amount (LKR)
                </span>
                <span className={styles.detail__value}>
                  {earnedAmount.toFixed(2)}
                </span>
              </div>
            )}
          </div>

          {!merchantId && role !== EarningsRoles.MerchantRedemptions && (
            <div className={styles.details__horizontal}>
              <div className={styles.detail__group__nested}>
                <span className={styles.detail__header}>
                  Platform Earned Amount (LKR)
                </span>
                <span className={styles.detail__value}>
                  {platformEarnedAmount.toFixed(2)}
                </span>
              </div>
              <div className={styles.detail__group__nested}>
                <span
                  className={styles.detail__header}
                >{`Merchant (${mid})`}</span>
                <span className={styles.detail__value}>{merchantName}</span>
              </div>
            </div>
          )}

          {status !== 'UNREDEEMED' && (
            <>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <AppButton
                    text='Renotify Customer'
                    onClick={handleOnNotifyCustomer}
                    type='button'
                    loading={false}
                    containerStyle={{ marginTop: '10px' }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <AppButton
                    text='Renotify Merchant'
                    onClick={handleOnNotifyMerchant}
                    type='button'
                    loading={false}
                    containerStyle={{ marginTop: '10px' }}
                  />
                </div>
              </div>
              <AppButton
                text='Unredeem'
                onClick={handleOnUnredeemClick}
                type='button'
                loading={false}
                containerStyle={{ marginTop: '10px' }}
              />
            </>
          )}
          <UnredeemPopup
            show={showUnredeemPopup}
            onChangeValue={handleOnPasswordChange}
            onSubmit={handleOnPasswordSubmit}
            onCancel={handleOnUnredeemCansel}
            password={password}
            loading={authLoading}
            error={passwordError}
          />
        </div>
        <div>{unredemptionError && renderError(unredemptionError)}</div>
      </div>
    );
  };
  const renderNoRedemtionError = () => {
    if (startDate && endDate) {
      return (
        <p>
          No redemptions for <b>{moment(startDate).format('DD/MM/YYYY')}</b> to{' '}
          <b>{moment(endDate).format('DD/MM/YYYY')}</b>
        </p>
      );
    } else if (searchRidIcode.trim() !== '') {
      return (
        <p>
          No {selectIdType === 'REFERENCE_CODE' ? 'reference' : 'redemptions'}{' '}
          for <b>{searchRidIcode}</b>
        </p>
      );
    } else if (selectSubMearchant !== 0) {
      const subMerchantDetails = subMerchants.find(
        (subMerchant) => subMerchant.id === selectSubMearchant
      );
      return (
        <p>
          No redemptions for{' '}
          <b>
            {subMerchantDetails.location} ( {subMerchantDetails.id} ){' '}
          </b>
        </p>
      );
    } else {
      return <p>No redemptions</p>;
    }
  };

  const handleExcelSheetDownload = () => {
    if (role === 'ROLE_THYAGA_ADMINISTRATOR') {
      downloadExcelSheetForAdmin();
    } else {
      downloadExcelSheetForMerchant();
    }
  };

  const downloadExcelSheetForAdmin = async () => {
    try {
      setState({ downloadStatus: 'DOWNLOADING' });
      let response;
      const request = {
        page: 0,
        subMerchantId: 0,
      };

      if (state.searchRidIcode) {
        request.searchKey = state.searchRidIcode;
        request.selectIdType = state.selectIdType;
        response = await getEarningsByRidRcode(request, token);
      } else {
        if (startDate && endDate) {
          request.startDate = moment(startDate).format();
          request.endDate = moment(endDate).format();
        }
        response = await getEarningsByDate(request, token);
      }

      const excelDate = response.earnings.map((redemption) => {
        const redemptionDetails = {
          'Redemption Id': redemption.id,
          'Customer Name': redemption.customerName,
          Merchant: redemption.merchantName,
          'Merchant Id': redemption.merchantId,
          'Sub Merchant Id': redemption.subMerchantId,
          'Reference Code': redemption.referenceCode,
          Type: redemption.type,
          'Promo Id': redemption.promoId,
          Amount: redemption.redeemedAmount,
          'Merchant Earning': redemption.earnedAmount,
          'Platform Earning': redemption.platformEarnedAmount,
          'Payable Status': redemption.payableStatus,
          Status: redemption.status,
          'Date/Time (IST)': moment(redemption.dateTime)
            .tz('Asia/Kolkata')
            .format('DD/MM/YYYY hh:mm A'),
        };

        return redemptionDetails;
      });
      exportToCSV(excelDate);
    } catch (error) {
      setState({ downloadStatus: 'ERROR' });
    }
  };

  const downloadExcelSheetForMerchant = async () => {
    setState({ downloadStatus: 'DOWNLOADING' });
    try {
      let response;
      if (selectSubMearchant === 0) {
        if (startDate && endDate) {
          response = await getAllRedemptionByDateInExcelSheet(
            token,
            moment(startDate).format(),
            moment(endDate).format(),
            checkForSubMerchantAdmin() ? currentUser.id : merchantId
          );
        } else {
          const searchType = state.searchRidIcode ? state.selectIdType : '';
          const searchKey = state.searchRidIcode;
          const id = checkForSubMerchantAdmin() ? currentUser.id : merchantId;
          const tempResponse = await getAllRedemptionInExcelSheet(id, token);
          if (searchType === 'REDEMPTION_ID') {
            response = tempResponse.filter(({ id }) =>
              id.toString().includes(searchKey)
            );
          } else if (searchType === 'REFERENCE_CODE') {
            response = tempResponse.filter(({ referenceCode }) =>
              referenceCode.toLowerCase().includes(searchKey.toLowerCase())
            );
          } else {
            response = tempResponse;
          }
        }
      } else {
        const selectSubMerchantForDownload = selectSubMearchant;
        if (startDate && endDate) {
          const tempResponse = await getAllRedemptionByDateInExcelSheet(
            token,
            moment(startDate).format(),
            moment(endDate).format()
          );
          response = tempResponse.filter(
            ({ subMerchantId }) =>
              subMerchantId === selectSubMerchantForDownload
          );
        } else {
          const searchType = state.searchRidIcode ? state.selectIdType : '';
          const searchKey = state.searchRidIcode;
          const tempResponse = await getAllRedemptionInExcelSheet(token);
          if (searchType === 'REDEMPTION_ID') {
            response = tempResponse.filter(
              ({ id, subMerchantId }) =>
                id.includes(searchKey) &&
                subMerchantId === selectSubMerchantForDownload
            );
          } else if (searchType === 'REFERENCE_CODE') {
            response = tempResponse.filter(
              ({ referenceCode, subMerchantId }) =>
                referenceCode.toLowerCase().includes(searchKey.toLowerCase()) &&
                subMerchantId === selectSubMerchantForDownload
            );
          } else {
            response = tempResponse.filter(
              ({ subMerchantId }) =>
                subMerchantId === selectSubMerchantForDownload
            );
          }
        }
      }

      const excelDate = response.map((redemption) => {
        const redemptionDetails = {
          'Redemption Id': redemption.id,
          'Customer Name': redemption.customerName,
          'Sub Merchant Id': redemption.subMerchantId,
          'Reference Code': redemption.referenceCode,
          Type: redemption.type,
          'Promo Id': redemption.promoId,
          Amount: redemption.amount,
          'Merchant Earning': redemption.merchantEarning,
          'Payable Status': redemption.payableStatus,
          Status: redemption.status,
          'Date/Time (IST)': moment(redemption.timestamp)
            .tz('Asia/Kolkata')
            .format('DD/MM/YYYY hh:mm A'),
        };
        return redemptionDetails;
      });
      exportToCSV(excelDate);
    } catch (error) {
      setState({ downloadStatus: 'ERROR' });
    }
    setState({ downloadStatus: '' });
  };

  const exportToCSV = (csvData) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'Redemptions' + fileExtension);
  };

  const renderEarnings = () => {
    const earningsToRender = earnings.map((earning) => {
      return (
        <EarningItem
          earning={earning}
          role={props.role}
          onClick={handleOnEarningSelect}
        />
      );
    });
    return (
      <div className={styles.offers__list}>
        {earnings.length === 0 ? (
          <div className={styles.redemption_error}>
            {renderNoRedemtionError()}
          </div>
        ) : (
          <div>
            {earningsToRender}
            {earningsToRender.length < 10 && total === 1 ? (
              ''
            ) : (
              <Pagination
                pageCount={total}
                onChange={handleOnPagechange}
                currentPage={current}
              />
            )}
          </div>
        )}
        <Drawer
          show={selectedEarning && !showRenotifyModal}
          onHide={handleOnEarningDeselect}
        >
          {selectedEarning && renderSelectedEarning()}
        </Drawer>
      </div>
    );
  };
  const renderHeader = () => {
    return (
      <div>
        <PageHeader text='Redemptions' />

        <div className={styles.search_area}>
          <div>
            <SearchByDate
              onStartDateChange={handleOnStartDatChange}
              onEndDateChange={handleOnEndDatChange}
              startDate={startDate}
              endDate={endDate}
            />
            <div className={styles.referenceCode_search}>
              <SearchRedemptionDrodown onSelectChange={handleOnSelectIdType} />
              <SearchByRedemtionIdOrReferanceCode
                onSubmit={handleOnSubmitSearchRidRcode}
                onChange={handleOnChangeSearchRidRcode}
                onClean={handleOnCleanSearchRidRcode}
                value={searchRidIcode}
                placeholder={
                  selectIdType === 'REDEMPTION_ID'
                    ? 'Redemption ID'
                    : 'Reference Code'
                }
              />
            </div>

            {!checkForSubMerchantAdmin() && (
              <SearchBySubMerchant
                subMerchants={subMerchants}
                onSelect={handleSubMearchant}
              />
            )}

            <div className={styles.download_button}>
              <Button onClick={handleExcelSheetDownload}>
                {(startDate && endDate) ||
                searchRidIcode ||
                selectSubMearchant !== 0
                  ? 'Download'
                  : 'Download All'}
              </Button>
              {downloadStatus === 'DOWNLOADING' && <p>Save as Excel Sheet</p>}
              {downloadStatus === 'ERROR' && (
                <p className={styles.download_error_message}>
                  Oops, something went wrong. Please try again
                </p>
              )}
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            className={styles.refresh_button}
            onClick={refreshEarnings}
            variant='outline-dark'
          >
            <img
              src={refreshIcon}
              alt='refresh'
              className={styles.refresh_icon}
            />
          </Button>
        </div>

        <div className={styles.table__header}>
          <span>Date (IST)</span>
          <span>Redemption Id</span>
          {props.role === 'ROLE_THYAGA_ADMINISTRATOR' ? (
            <span>Merchant</span>
          ) : null}
          <span>Sub Merchant</span>
          <span>Status</span>
          <span>Earned (LKR)</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.root__div}>
        {renderHeader()}
        {earningsLoading
          ? renderLoading()
          : earningsError
          ? renderError(earningsError)
          : renderEarnings()}
        {selectedEarning && (
          <RedemptionRenotifyModal
            show={showRenotifyModal}
            handleClose={handleClose}
            handleRenotifyRedemption={handleRenotifyRedemption}
            overrideNumber={overrideNumber}
            recipient={recipient}
            handleOverrideNumber={handleOverrideNumber}
            handleAddField={handleAddField}
            handleRemoveField={handleRemoveField}
            mobileNumberFields={mobileNumberFields}
            handleMobileChange={handleMobileChange}
            resendSuccess={resendSuccess}
            validated={validated}
            selectedEarning={selectedEarning}
            resendLoading={resendLoading}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.user,
    role: state.auth.auth.details.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewEarnings);
